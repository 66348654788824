import { Component, OnInit } from '@angular/core';
import { QueryDetailsModel } from '../querydetailsmodel';
import { ApiService } from '../api.service';
import { Router } from "@angular/router";
import { QueryDetailsResponse } from '../queryDetailsReponse';
@Component({
  selector: 'app-querydetails',
  templateUrl: './querydetails.component.html',
  styleUrls: ['./querydetails.component.css']
})
export class QuerydetailsComponent implements OnInit {
  queryDetailsModel = new QueryDetailsModel();
  crfResArray: QueryDetailsResponse[] = [];
  crfdatalength: any;
  visitname: any;
  status :any;
  constructor(private apiService: ApiService, private route: Router) {
  }
  ngOnInit() {
    let logiResoutputdata = JSON.parse(sessionStorage.getItem("logindatas") || '{}');
    this.queryDetailsModel.userId = logiResoutputdata.result.userId;
    let queriesList = JSON.parse(sessionStorage.getItem("querydetailsdata") || '{}');
    this.visitname = queriesList.visitName;
    //console.log(this.visitname);
    this.queryDetailsModel.queryId = queriesList.queryId;
    this.apiService.queriesDetailsmethod(this.queryDetailsModel)
      .subscribe(queryDetailsdata => {
        // console.log(queryDetailsdata);
        for (let i = 0; i < queryDetailsdata.result.length; i++) {
          if(queryDetailsdata.result[i].status=='OPEN' && queryDetailsdata.result[i].updatedFrom =='ROLE_DATA_MANAGER'){
            this.status=true;
          }
          //console.log(queryDetailsdata.result.length);
          var crfRes = new QueryDetailsResponse();
          this.crfdatalength = queryDetailsdata.result.length;
          crfRes.comments = queryDetailsdata.result[i].comments;
          crfRes.createdDate = queryDetailsdata.result[i].createdDate;
          crfRes.detailId = queryDetailsdata.result[i].detailId;
          crfRes.modifiedAnswer = queryDetailsdata.result[i].modifiedAnswer;
          crfRes.originalAnswer = queryDetailsdata.result[i].originalAnswer;
          crfRes.patientId = queryDetailsdata.result[i].patientId;
          crfRes.queryId = queryDetailsdata.result[i].queryId;
          crfRes.questionName = queryDetailsdata.result[i].questionName;
          crfRes.questionComments = queryDetailsdata.result[i].questionComments;
          crfRes.questionId = queryDetailsdata.result[i].questionId;
          crfRes.questionOptions = queryDetailsdata.result[i].questionOptions;
          crfRes.questionType = queryDetailsdata.result[i].questionType;
          crfRes.status = queryDetailsdata.result[i].status;
          crfRes.updatedFrom = queryDetailsdata.result[i].updatedFrom;
          crfRes.updatedUserId = queryDetailsdata.result[i].updatedUserId;
          crfRes.visitId = queryDetailsdata.result[i].visitId;
          this.crfResArray.push(crfRes);
          // console.log(this.crfResArray);
        }
      });
  }
  onChange(e: any) {
    // console.log(e)
    sessionStorage.setItem('querydetails', JSON.stringify(e));
    //if status is open for all forms
    this.route.navigate(['updatequery']);
  }
 
}
