import { Component, OnInit } from '@angular/core';
import { QueriesListModel } from '../querieslistmodel';
import { ApiService } from '../api.service';
import { Router } from "@angular/router";
import { QueriesListResponse } from '../queriesListResponse'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QueryFilterModel } from '../queryfiltermodel';
@Component({
  selector: 'app-querieslist',
  templateUrl: './querieslist.component.html',
  styleUrls: ['./querieslist.component.css']
})
export class QuerieslistComponent implements OnInit {
  queriesListModel = new QueriesListModel();
  crfResArray: QueriesListResponse[] = [];
  queryFilterModel = new QueryFilterModel();
  crfdatalength: any;
  statusAl: any;
  statusOp: any;
  statusRe: any;
  statusAn: any;
  statusCl: any;
  status: string;
  querydata: any;
  constructor( private apiService: ApiService, private route: Router, private modalService: NgbModal) {
  }
  open(content: any) {
    this.modalService.open(content);
  }
  ngOnInit() {
    this.status="All"
    this.statusAl = "All"
    this.statusOp = "OPEN"
    this.statusRe = "REQUERY"
    this.statusAn = "ANSWERED"
    this.statusCl = "CLOSED"
    let logiResoutputdata = JSON.parse(sessionStorage.getItem("logindatas") || '{}');
    this.queriesListModel.userId = logiResoutputdata.result.userId;
    this.queriesListModel.siteId = logiResoutputdata.result.otherId;
    this.apiService.queriesListmethod(this.queriesListModel)
      .subscribe(queriesListdata => {
        //  console.log(queriesListdata);
        this.querydata = queriesListdata;
        this.arraypush();
      });
  }
  arraypush() {
    this.crfResArray.splice(0, this.crfResArray.length);
    for (let i = 0; i < this.querydata.result.length; i++) {
      // console.log(queriesListdata.result.length);
      var crfRes = new QueriesListResponse();
      this.crfdatalength = this.querydata.result.length;
      crfRes.answer = this.querydata.result[i].answer;
      crfRes.assignedRole = this.querydata.result[i].assignedRole;
      crfRes.assignedUserId = this.querydata.result[i].assignedUserId;
      crfRes.categoryId = this.querydata.result[i].categoryId;
      crfRes.comments = this.querydata.result[i].comments;
      crfRes.createdDate = this.querydata.result[i].createdDate;
      crfRes.patientId = this.querydata.result[i].patientId;
      crfRes.queryId = this.querydata.result[i].queryId;
      crfRes.questionId = this.querydata.result[i].questionId;
      crfRes.questionName = this.querydata.result[i].questionName;
      crfRes.raisedUserId = this.querydata.result[i].raisedUserId;
      crfRes.sectionId = this.querydata.result[i].sectionId;
      crfRes.status = this.querydata.result[i].status;
      crfRes.visitName = this.querydata.result[i].visitName;
      this.crfResArray.push(crfRes);
      //console.log(this.crfResArray);
    }
  }
  onChange(e: any) {
    //console.log(e)
    sessionStorage.setItem('querydetailsdata', JSON.stringify(e));
    //if status is open for all forms
    if (e) {
      this.route.navigate(['querydetails']);
    }
  }
  Change(e) {
    this.status = e;
    //console.log(e)
    if (e == "All") {
      this.apiService.queriesListmethod(this.queriesListModel)
        .subscribe(queriesListdata => {
          // console.log(queriesListdata);
          this.querydata = queriesListdata;
          this.arraypush();
        })
    } else {
      this.status = e;
      let logiResoutputdata = JSON.parse(sessionStorage.getItem("logindatas") || '{}');
      this.queryFilterModel.userId = logiResoutputdata.result.userId;
      this.queryFilterModel.siteId = logiResoutputdata.result.otherId;
      this.queryFilterModel.status = e;
      this.apiService.queryfiltermethod(this.queryFilterModel)
        .subscribe(queriesListdata => {
          // console.log(queriesListdata)
          this.querydata = queriesListdata;
          this.arraypush();
        })
    }
  }
 
}
