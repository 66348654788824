export class LoginResponseModel {
    "message": String;
    "userId": Number;
    "status": String;
    "displayName": String;
    "displayPictureUrl": String;
    "roleId": Number;
    "otherId": Number;
    "higherUserId": Number

}
