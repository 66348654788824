import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { LoginModelRequest } from './loginmodelrequest';
import { StudyModelRequest } from './studymodelrequest';
import { catchError } from 'rxjs/operators';
import { MyprofileRequestModel } from './myprofilerequestmodel';
import { UpdateLabReportModel } from './updatelabreportmodelrequest';
import { ApproveRequest } from './approverequest';
import { NewParticipantModelRequest } from "./newparticipantmodelrequest";
import { CrfVisitRequest } from './crfvisitrequest';
import { LabReportRequest } from './LabReportRequest';
import { AddLabReportsRequest } from './addlabreportrequest';
import { CrfQuestionsRequest } from './crfquestionsrequest';
import { CrfDemographicRequest } from './crfdemographicrequest';
import { CrfSubmittedModel } from './crfSubmittedmodel';
import { PatientDairyModel } from './patientdairymodel'
import { FormSubmittedModel } from './formsforsumittedmodel';
import { QueriesListModel } from './querieslistmodel';
import { QueryDetailsModel } from './querydetailsmodel';
import { UpdateQuery } from './updatequery';
import { UpadteDataModel } from './updatedatamodel';
import { PatientModel } from './patientmodel';
import { QueryFilterModel } from './queryfiltermodel';
import { changePasswordModel } from './changePasswordModel';


@Injectable({ providedIn: 'root' })
export class ApiService {

  baseURL: any = "https://trials.cliniv.in/";
  appName: any = "EDC/";

  constructor(private http: HttpClient) { }

  private handleError(error: Response) {
    return Observable.throw(error);
  }
  //login post method
  authenticateLogin(loginReq: LoginModelRequest): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(loginReq);
    console.log(body);
    var loginUrl = this.baseURL + this.appName + "login/authenticate";
    return this.http.post(loginUrl,
      body, { 'headers': headers });

  }
  // study detail get method
  studyDetailMethod(studyModelRequest: StudyModelRequest): Observable<any> {
    var url = this.baseURL + this.appName + "study/getStudyDetailsById";
    url = url + " ?studyId=" + "1";
    url = url + "&userId=" + studyModelRequest.userId;
    console.log("hi" + url);
    return this.http.get(url).pipe(catchError(this.handleError));
  }
  //studydocuments get api
  studyDocdumentMethod(studyModelRequest: StudyModelRequest): Observable<any> {
    var url1 = this.baseURL + this.appName + "study/getStudyDocumentsById";
    url1 = url1 + "?studyId=" + studyModelRequest.studyId
    url1 = url1 + "&type=" + "ALL";
    url1 = url1 + "&userId=" + studyModelRequest.userId;
    console.log("hi" + url1);
    return this.http.get<any>(url1).pipe(catchError(this.handleError))
  }

  //participant data  url
  participantmethod(patientModel: PatientModel): Observable<any> {
    var participanturl = this.baseURL + this.appName + "patient/getAllPatientsBySite";
    participanturl = participanturl + "?studyId=" + patientModel.studyId;
    participanturl = participanturl + "&userId=" + patientModel.userId;
    participanturl = participanturl + "&siteId=" + patientModel.siteId;
    return this.http.get(participanturl).pipe(catchError(this.handleError));

  }
  //myprofile data  url
  myprofilemethod(myprofileReq: MyprofileRequestModel): Observable<any> {
    var myprofileturl = this.baseURL + this.appName + "login/getSiteProfile";
    myprofileturl = myprofileturl + "?userId=" + myprofileReq.userId;
    myprofileturl = myprofileturl + "&siteId=" + myprofileReq.siteId;
    return this.http.get(myprofileturl).pipe(catchError(this.handleError))
  }
  //update labreport post method
   updatelabreportmethod(updateLabReport: UpdateLabReportModel): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(updateLabReport);
    //console.log(body);
    var updatelabreportUrl = this.baseURL + this.appName + "patient/updatePatientLab";
    return this.http.post(updatelabreportUrl,
      body, { 'headers': headers }).pipe(catchError(this.handleError))

  }
  //Incusion_ExclusionData data  url
  inclusion_exclusiondatamethod(StudyModelRequest: StudyModelRequest): Observable<any> {
    var inclusion_exclusiondataurl = this.baseURL + this.appName + "study/getInExDataByStudyId";
    inclusion_exclusiondataurl = inclusion_exclusiondataurl + "?studyId=" + StudyModelRequest.studyId;
    inclusion_exclusiondataurl = inclusion_exclusiondataurl + "&userId=" + StudyModelRequest.userId;
    return this.http.get(inclusion_exclusiondataurl).pipe(catchError(this.handleError));
  }
  //approvepatient  post method
  approvemethod(approveRequest: ApproveRequest): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(approveRequest);
    //console.log(body)
    var approveUrl = this.baseURL + this.appName + "patient/approvePatientICF";
    return this.http.post(approveUrl,
      body, { 'headers': headers }).pipe(catchError(this.handleError));

  }
  //enrolledpatient  post method
  enrolledpatientmethod(ParticipantModelRequest: NewParticipantModelRequest): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(ParticipantModelRequest);
    //console.log(body)
    var enrolledpatientUrl = this.baseURL + this.appName + "patient/enrollNewPatient";
    return this.http.post(enrolledpatientUrl,
      body, { 'headers': headers }).pipe(catchError(this.handleError));

  }
  //crfdata data  url
  crfmethod(crfVisitReque: CrfVisitRequest): Observable<any> {
    var crfvisiturl = this.baseURL + this.appName + "patient/getPatientVisitsByStudy";
    crfvisiturl = crfvisiturl + "?patientId=" + crfVisitReque.patientId;
    crfvisiturl = crfvisiturl + "&studyId=" + crfVisitReque.studyId;
    crfvisiturl = crfvisiturl + "&userId=" + crfVisitReque.userId;
    return this.http.get(crfvisiturl).pipe(catchError(this.handleError));
  }
  //labreport data  url
  labreportmethod(labReportReq: LabReportRequest): Observable<any> {
    var labReporturl = this.baseURL + this.appName + "patient/getLabReports";
    labReporturl = labReporturl + "?patientId=" + labReportReq.patientId;
    labReporturl = labReporturl + "&userId=" + labReportReq.userId;
    return this.http.get(labReporturl).pipe(catchError(this.handleError));
  }
  //new labreport api
  addlabreportsmethodmethod(addLabReportsReq: AddLabReportsRequest): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(addLabReportsReq);
   // console.log(body)
    var addlabreportUrl = this.baseURL + this.appName + "patient/updateLabReports";
    return this.http.post(addlabreportUrl,
      body, { 'headers': headers }).pipe(catchError(this.handleError));

  }
  //If fill Status is OPEN (For Any Form) for crf details api
  crfstatusopenmethod(CrfQuestionsReq: CrfQuestionsRequest): Observable<any> {
    var crfopenstatusurl = this.baseURL + this.appName + "question/getAllQuestionsById";
    crfopenstatusurl = crfopenstatusurl + "?sectionId=" + CrfQuestionsReq.sectionId;
    crfopenstatusurl = crfopenstatusurl + "&userId=" + CrfQuestionsReq.userId;
    return this.http.get(crfopenstatusurl).pipe(catchError(this.handleError));
  }
  //If fill Status is Submitted  only visitNmae:Demographic of api
  Demographicnmethod(crfDemographicReq: CrfDemographicRequest): Observable<any> {
    var crfdemographicurl = this.baseURL + this.appName + "patient/getPatientVisitsBySection";
    crfdemographicurl = crfdemographicurl + "?sectionId=" + crfDemographicReq.sectionId;
    crfdemographicurl = crfdemographicurl + "&userId=" + crfDemographicReq.userId;
    crfdemographicurl = crfdemographicurl + "&studyId=" + crfDemographicReq.studyId;
    crfdemographicurl = crfdemographicurl + "&visitId=" + crfDemographicReq.visitId;
    crfdemographicurl = crfdemographicurl + "&patientId=" + crfDemographicReq.patientId;
   // console.log(crfdemographicurl)
    return this.http.get(crfdemographicurl).pipe(catchError(this.handleError));
  }
  //If fill Status is Submitted all forms expect demographic
  statusSubmitmethod(crfSubmittedModel: CrfSubmittedModel): Observable<any> {
    var statusSubmittedurl = this.baseURL + this.appName + "question/getUpdatedFormsByPatient";
    statusSubmittedurl = statusSubmittedurl + "?sectionId=" + crfSubmittedModel.sectionId;
    statusSubmittedurl = statusSubmittedurl + "&userId=" + crfSubmittedModel.userId;
    statusSubmittedurl = statusSubmittedurl + "&patientId=" + crfSubmittedModel.patientId;
    return this.http.get(statusSubmittedurl).pipe(catchError(this.handleError));
  }
  //status is submitted all forms expect demographic to show forms api
  submitstatusmethod(formSubmittedModel: FormSubmittedModel): Observable<any> {
    var statusSubmittedurl = this.baseURL + this.appName + "question/getUpdatedDataByCategory";
    statusSubmittedurl = statusSubmittedurl + "?categoryId=" + formSubmittedModel.categoryId;
    statusSubmittedurl = statusSubmittedurl + "&userId=" + formSubmittedModel.userId;
    statusSubmittedurl = statusSubmittedurl + "&patientId=" + formSubmittedModel.patientId;
    return this.http.get(statusSubmittedurl).pipe(catchError(this.handleError));
  }
  //patientdairy api 
  patientdairymethod(patientDairyModel: PatientDairyModel): Observable<any> {
    var patientdairyurl = this.baseURL + this.appName + "patient/getPatientDairyByPatient";
    patientdairyurl = patientdairyurl + "?patientId=" + patientDairyModel.patientId;
    patientdairyurl = patientdairyurl + "&studyId=" + patientDairyModel.studyId;
    patientdairyurl = patientdairyurl + "&fromDate=" + patientDairyModel.fromDate;
    patientdairyurl = patientdairyurl + "&toDate=" + patientDairyModel.toDate;
    patientdairyurl = patientdairyurl + "&userId=" + patientDairyModel.userId;
   // console.log(patientdairyurl);
    return this.http.get(patientdairyurl).pipe(catchError(this.handleError));
  }
  //status open api
  statusopenmethod(crfarray): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(crfarray);
   // console.log(body)
    var statusopenUrl = this.baseURL + this.appName + "question/submitQuestions";
    return this.http.post(statusopenUrl,
      body, { 'headers': headers }).pipe(catchError(this.handleError));

  }
  // //status submitted and update the data api//
  statussubmittedmethod(statussubmittedmethod): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(statussubmittedmethod);
   // console.log(body)
    var statussubmittedUrl = this.baseURL + this.appName + "question/updateForm";
    return this.http.post(statussubmittedUrl,
      body, { 'headers': headers }).pipe(catchError(this.handleError));

  }
  //queriesList api
  queriesListmethod(queriesListModel: QueriesListModel): Observable<any> {
    var queriesListurl = this.baseURL + this.appName + "study/getQueriesBySite";
    queriesListurl = queriesListurl + "?userId=" + queriesListModel.userId;
    queriesListurl = queriesListurl + "&siteId=" + queriesListModel.siteId;
    return this.http.get(queriesListurl).pipe(catchError(this.handleError));
  }
  //queriesDetails  api
  queriesDetailsmethod(queryDetailsModel: QueryDetailsModel): Observable<any> {
    var queriesDetailsurl = this.baseURL + this.appName + "study/getQueryDetailsById";
    queriesDetailsurl = queriesDetailsurl + "?queryId=" + queryDetailsModel.queryId;
    queriesDetailsurl = queriesDetailsurl + "&userId=" + queryDetailsModel.userId;
    return this.http.get(queriesDetailsurl).pipe(catchError(this.handleError));
  }
  // //update query
  updatequerymethod(updateQuery: UpdateQuery): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(updateQuery);
  //  console.log(body)
    var updatequeryUrl = this.baseURL + this.appName + "study/updateQueryStatus";
    return this.http.post(updatequeryUrl,
      body, { 'headers': headers }).pipe(catchError(this.handleError))

  }
  // //update query
  updatesubjectmethod(crfarray): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(crfarray);
   // console.log(body)
    var updatesubjectUrl = this.baseURL + this.appName + "question/submitStatusForm";
    return this.http.post(updatesubjectUrl,
      body, { 'headers': headers }).pipe(catchError(this.handleError));
  }
  //if fill status is OPEN for Treatment, Medication Forms for Calendar
  participantdairyopenmethod(crfarray): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(crfarray);
   //console.log(body)
    var statusopenUrl = this.baseURL + this.appName + "question/submitMedicationForm";
    return this.http.post(statusopenUrl,
      body, { 'headers': headers }).pipe(catchError(this.handleError));

  }
  //if fill status is OPEN for Adverse Events Form
  participantdairyopenAEmethod(crfarray): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(crfarray);
   // console.log(body)
    var statusopenAEUrl = this.baseURL + this.appName + "question/submitAEForm";
    return this.http.post(statusopenAEUrl,
      body, { 'headers': headers }).pipe(catchError(this.handleError));

  }
  //update data status submitted
  updateSubmitmethod(upadteDataModel: UpadteDataModel): Observable<any> {
    var updatedataurl = this.baseURL + this.appName + "question/getUpdatedFormsByPatientDate";
    updatedataurl = updatedataurl + "?patientId=" + upadteDataModel.patientId;
    updatedataurl = updatedataurl + "&sectionId=" + upadteDataModel.sectionId;
    updatedataurl = updatedataurl + "&userId=" + upadteDataModel.userId;
    updatedataurl = updatedataurl + "&fromDate=" + upadteDataModel.fromDate;
    updatedataurl = updatedataurl + "&toDate=" + upadteDataModel.toDate;

    return this.http.get(updatedataurl).pipe(catchError(this.handleError));
  }
  //queryfilter api
  queryfiltermethod(queryFilterModel: QueryFilterModel): Observable<any> {
    var queryfilterurl = this.baseURL + this.appName + "study/getQueriesBySiteFilter";
    queryfilterurl = queryfilterurl + "?siteId=" + queryFilterModel.siteId;
    queryfilterurl = queryfilterurl + "&userId=" + queryFilterModel.userId;
    queryfilterurl = queryfilterurl + "&status=" + queryFilterModel.status;
   // console.log(queryfilterurl)
    return this.http.get(queryfilterurl).pipe(catchError(this.handleError));
  }
  //change password post method
  changePasswordmethod(changePassword: changePasswordModel): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(changePassword);
    // console.log(body);
    var changePassworddata = this.baseURL + this.appName + "login/changepassword";
    return this.http.post(changePassworddata,
      body, { 'headers': headers });
  }
}