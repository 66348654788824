import { Component, OnInit } from '@angular/core';
import { CrfDemographicRequest } from '../crfdemographicrequest';
import { ApiService } from '../api.service';
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { UpdateVisitModel } from '../updatevisitmodel';
import { Location, DatePipe } from '@angular/common'

@Component({
  selector: 'app-crfvisit-demographic',
  templateUrl: './crfvisit-demographic.component.html',
  styleUrls: ['./crfvisit-demographic.component.css']
})
export class CrfvisitDemographicComponent implements OnInit {
  updateVisitModel = new UpdateVisitModel();
  thisIsMyForm: FormGroup;
  section: any;
  crfDemographicReq = new CrfDemographicRequest();
  visitName: any
  array: any;
  crfarray: any = [];
  maxLength: any;
  age:any;
     //today's date show for calender
     todayDate: Date = new Date();
  constructor(private datePipe: DatePipe,   private formBuilder: FormBuilder, private route: Router, private apiService: ApiService,) {
    this.thisIsMyForm = new FormGroup({
      formArrayName: this.formBuilder.array([])
    })
    // console.log(this.thisIsMyForm);
  }
  
  ngOnInit() {
    let loginResoutputdata = JSON.parse(sessionStorage.getItem("logindatas") || '{}');
    let Crfpatientsingledata = JSON.parse(sessionStorage.getItem("Crfpatientsingledata") || '{}');
    this.crfDemographicReq.userId = loginResoutputdata.result.userId;
    this.crfDemographicReq.sectionId = Crfpatientsingledata.sectionId;
    this.crfDemographicReq.patientId = Crfpatientsingledata.patientId;
    this.crfDemographicReq.studyId = Crfpatientsingledata.studyId;
    this.crfDemographicReq.visitId = Crfpatientsingledata.visitId;
    //For display the VisitName based on Visit Patient
    this.visitName = Crfpatientsingledata.visitName;
    this.apiService.Demographicnmethod(this.crfDemographicReq)
      .subscribe(crfDemographicdata => {
        // console.log(crfDemographicdata)
        this.section = crfDemographicdata.result.questions;
        console.log(this.section);
        this.buildForm();
      });
  }
  buildForm() {
    const controlArray = this.thisIsMyForm.get('formArrayName') as FormArray;
    let singlepatientfulldata = JSON.parse(sessionStorage.getItem("singlepatientfulldata") || '{}');
    let crfpatientfulldata = JSON.parse(sessionStorage.getItem("Crfpatientsingledata") || '{}');
    let loginResoutputdata = JSON.parse(sessionStorage.getItem("logindatas") || '{}');
    //console.log("Data" + this.section);
    Object.keys(this.section).forEach((i) => {
      // console.log( this.section[i].answer.length)
      // console.log(this.section[i]);
      controlArray.push(
        this.formBuilder.group({
          answer: this.section[i].answer,
          questionId: this.section[i].questionId,
          sectionId: this.section[i].sectionId,
          questionTitle: this.section[i].questionTitle,
          patientId: singlepatientfulldata.patientId,
          userId: loginResoutputdata.result.userId,
          categoryId: crfpatientfulldata.sectionId,
          remarks: "",
        })
      )
    })
    //console.log(controlArray.controls)
  }
  equal() {
    this.crfarray = [];
    if (this.thisIsMyForm.valid) {
      this.array = this.thisIsMyForm.value;
      //console.log(this.array.formArrayName)
      for (let datum of this.array.formArrayName) {
        console.log(datum.questionTitle)
        
        if (typeof (datum.answer) !== 'undefined') {
          if (datum.questionTitle == "Age") {
            datum.answer = this.age;
          }
          if (datum.answer.toString().includes("GMT")) {
            datum.answer = this.datePipe.transform(datum.answer, 'yyyy-MM-dd')
          }
          datum.answer = datum.answer.toString();
          this.crfarray.push(datum);  // add data into array
        }
      }
      console.log(this.crfarray)
       //console.log(this.crfarray)
    this.apiService.statussubmittedmethod(this.crfarray)
    .subscribe(visitdata => {
      // console.log(visitdata)
      this.route.navigate(['crf']);
    })
    } else {
      alert("Please fill all the mandatory fields")
      // console.log('Not Submit')
    }
   
  }
  //length validations
  onKeypressEvent(x, event) {
    //console.log(x)
    this.maxLength = x.length;
    // console.log(event.target.value.length);
    if (event.target.value.length == this.maxLength) {
      return false;
    }
  }
  onDateChange(x:any,$event:any){
    console.log($event.value)
    let patientDateStrF = new Date($event.value)
    console.log(new Date().getFullYear()-patientDateStrF.getFullYear())
    this.age=new Date().getFullYear()-patientDateStrF.getFullYear();
    if (x.questionTitle=="Date of Birth") {
      for (let index = 0; index < this.section.length; index++) {
        if (this.section[index].questionTitle == "Age") {
          this.section[index].answer = this.age;
        }
      }
    }
  }
  BmiMethod() {
    alert("This Field is automatically filled")
  }
}
