export class QueriesListResponse {
  "answer": String;
  "assignedRole": String;
  "assignedUserId": Number;
  "categoryId": Number;
  "comments": String;
  "createdDate": Number;
  "patientId": String;
  "queryId": Number;
  "questionId": Number;
  "questionName": String;
  "raisedUserId": Number;
  "sectionId": Number;
  "status": String;
  "visitName": String;
}