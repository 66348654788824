export class SubmitVisitModel {

    "questionId": Number;
    "visitId": Number;
    "userId": Number;
    "patientId": String;
    "studyId": Number;
    "sectionId": Number;
    "answer": String;
    "remarks": String;

}