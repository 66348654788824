import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { StudyDocumentsComponent } from './study-documents/study-documents.component';
import { ParticipantComponent } from './participant/participant.component';
import { HttpClientModule ,HttpClient} from '@angular/common/http';
import { ApiService } from './api.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { UpdatelabreportComponent } from './updatelabreport/updatelabreport.component';
import { InclusionExclusiondataComponent } from './inclusion-exclusiondata/inclusion-exclusiondata.component';
import { ApprovepatientComponent } from './approvepatient/approvepatient.component';
import { NewpatientComponent } from './newpatient/newpatient.component';
import { SignaturePadModule } from 'angular2-signaturepad';
import { CrfComponent } from './crf/crf.component';
import { LabreportComponent } from './labreport/labreport.component';
import { NewlabreportComponent } from './newlabreport/newlabreport.component';
import { CrfvisitquestionsComponent } from './crfvisitquestions/crfvisitquestions.component';
import { CrfvisitDemographicComponent } from './crfvisit-demographic/crfvisit-demographic.component';
import { CrfVisitSubmitedComponent } from './crf-visit-submited/crf-visit-submited.component';
import { PatientDairyComponent } from './participantdairy/patient-dairy.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AddnewmedicalformComponent } from './addnewmedicalform/addnewmedicalform.component';
import { QuerieslistComponent } from './querieslist/querieslist.component';
import { QuerydetailsComponent } from './querydetails/querydetails.component';
import { UpdatequeryComponent } from './updatequery/updatequery.component';
import { UpdatesubjectstatusComponent } from './updatesubjectstatus/updatesubjectstatus.component';
import { CrfformdataComponent } from './crfformdata/crfformdata.component';
import { PatientdairydetailsComponent } from './patientdairydetails/patientdairydetails.component';
import { DatePipe } from '@angular/common';
import { PatientdairydetailsopenformComponent } from './submitpatientdairydetail/patientdairydetailsopenform.component';
import { PatientdairydetailssubmitformComponent } from './participantdairylist/patientdairydetailssubmitform.component';
import { PatentdairyupdateformComponent } from './patentdairyupdate/patentdairyupdateform.component';
import { PatentdairynewformComponent } from './patentdairysubmit/patentdairynewform.component';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { BnNgIdleService } from 'bn-ng-idle';
import { HeaderComponent } from './header/header.component';
import { LoaderInterceptor } from './Loader.Interceptor';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import { DemoMaterialModule } from './material-module';
import { HelpComponent } from './help/help.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    StudyDocumentsComponent,
    ParticipantComponent,
    MyProfileComponent,
    UpdatelabreportComponent,
    InclusionExclusiondataComponent,
    ApprovepatientComponent,
    NewpatientComponent,
    CrfComponent,
    LabreportComponent,
    NewlabreportComponent,
    CrfvisitquestionsComponent,
    CrfvisitDemographicComponent,
    CrfVisitSubmitedComponent,
    PatientDairyComponent,
    AddnewmedicalformComponent,
    QuerieslistComponent,
    QuerydetailsComponent,
    UpdatequeryComponent,
    UpdatesubjectstatusComponent,
    CrfformdataComponent,
    PatientdairydetailsComponent,
    PatientdairydetailsopenformComponent,
    PatientdairydetailssubmitformComponent,
    PatentdairyupdateformComponent,
    PatentdairynewformComponent,
    HeaderComponent,
    HelpComponent,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    PdfViewerModule,
    Ng2SearchPipeModule,
    SignaturePadModule,
    BrowserAnimationsModule,
    DemoMaterialModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    TranslateModule.forRoot({
      loader:{
        provide:TranslateLoader,
        useFactory:(http:HttpClient)=>{
          return new TranslateHttpLoader(http,'./assets/i18n/', '.json');},
        deps:[HttpClient]
      }
          })
      
  ],
  providers: [NgbModal,
    ApiService, DatePipe,BnNgIdleService,DemoMaterialModule,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
