import { Component, OnInit } from '@angular/core';
import { FormSubmittedModel } from '../formsforsumittedmodel';
import { ApiService } from '../api.service';
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { UpdateVisitModel } from '../updatevisitmodel';
import { Location, DatePipe } from '@angular/common'

@Component({
  selector: 'app-crfformdata',
  templateUrl: './crfformdata.component.html',
  styleUrls: ['./crfformdata.component.css']
})
export class CrfformdataComponent implements OnInit {
  formSubmittedModel = new FormSubmittedModel();
  updateVisitModel = new UpdateVisitModel();
  thisIsMyForm: FormGroup;
  section: any;
  visitName: any
  array: any;
  crfarray: any = [];
  maxLength: any;
   //today's date show for calender
   todayDate: Date = new Date();
  constructor(private datePipe: DatePipe,  private formBuilder: FormBuilder, private route: Router, private apiService: ApiService,) {
    this.thisIsMyForm = new FormGroup({
      formArrayName: this.formBuilder.array([],Validators.required)
    })
    //console.log(this.thisIsMyForm);
  }
  ngOnInit() {
    let loginResoutputdata = JSON.parse(sessionStorage.getItem("logindatas") || '{}');
    let Crfpatientsingledata = JSON.parse(sessionStorage.getItem("Crfpatientsingledata") || '{}');
    this.formSubmittedModel.userId = loginResoutputdata.result.userId;
    this.formSubmittedModel.patientId = Crfpatientsingledata.patientId;
    let medicalList = JSON.parse(sessionStorage.getItem("medicalList") || '{}');
    this.visitName = Crfpatientsingledata.visitName;
    this.formSubmittedModel.categoryId = medicalList.sectionId;
    // console.log(this.formSubmittedModel.categoryId)
    this.apiService.submitstatusmethod(this.formSubmittedModel)
      .subscribe(crfopenstatusdata => {
        //console.log(crfopenstatusdata)
        this.section = crfopenstatusdata.result.questions;
        // console.log(this.section);
        this.buildForm();
      });
  }
  
  buildForm() {
    const controlArray = this.thisIsMyForm.get('formArrayName') as FormArray;
    let singlepatientfulldata = JSON.parse(sessionStorage.getItem("singlepatientfulldata") || '{}');
    let Crfpatientsingledata = JSON.parse(sessionStorage.getItem("Crfpatientsingledata") || '{}');
    let loginResoutputdata = JSON.parse(sessionStorage.getItem("logindatas") || '{}');
    let medicalList = JSON.parse(sessionStorage.getItem("medicalList") || '{}');
    //console.log("Data" + this.section);
    Object.keys(this.section).forEach((i) => {
      // console.log(this.section[i]);
      controlArray.push(
        this.formBuilder.group({
          answer: this.section[i].answer,
          questionId: this.section[i].questionId,
          categoryId: medicalList.sectionId,
          questionTitle: this.section[i].questionTitle,
          patientId: singlepatientfulldata.patientId,
          userId: loginResoutputdata.result.userId,
          sectionId: Crfpatientsingledata.sectionId,
          remarks: "",
        })
      )
    })
    //console.log(controlArray.controls)
  }
  equal() {
    this.crfarray = [];
    if (this.thisIsMyForm.valid) {
      this.array = this.thisIsMyForm.value;
      //console.log(this.array.formArrayName)
      for (let datum of this.array.formArrayName) {
        if (typeof (datum.answer) !== 'undefined') {
          if (datum.answer.toString().includes("GMT")) {
            datum.answer = this.datePipe.transform(datum.answer, 'yyyy-MM-dd')
          }
          datum.answer = datum.answer.toString();
          this.crfarray.push(datum);  // add data into array
        }
      }
      console.log(this.crfarray)
       this.apiService.statussubmittedmethod(this.crfarray)
      .subscribe(visitdata => {
        // console.log(visitdata)
        this.route.navigate(['CrfVisitSubmited']);
      })
    } else {
      alert("Please fill all the mandatory fields")
      // console.log('Not Submit')
    }
 
  }
  //length validations
  onKeypressEvent(x, event) {
    //console.log(x)
    this.maxLength = x.length;
    if (event.target.value.length == this.maxLength) {
      return false;
    }
  }
  
}
