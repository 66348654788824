export class QueryDetailsResponse {
  "comments": String;
  "createdDate": Number;
  "detailId": Number;
  "modifiedAnswer": String;
  "originalAnswer": Number;
  "patientId": String;
  "queryId": String;
  "questionComments": String;
  "questionId": Number;
  "questionName": String;
  "questionOptions": String;
  "questionType": String;
  "status": String;
  "updatedFrom": String;
  "updatedUserId": Number;
  "visitId": Number;
}