import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from "@angular/router";
import { CrfVisitRequest } from '../crfvisitrequest';
import { CrfResponse } from '../CrfResponse';
@Component({
  selector: 'app-crf',
  templateUrl: './crf.component.html',
  styleUrls: ['./crf.component.css']
})
export class CrfComponent implements OnInit {
  crfVisitreq = new CrfVisitRequest();
  dropParticipantdata: any;
  patientId: Number;
  protocolId: Number;
  crfdatalength: Number;
  patientenrolleddate: any;
  registerdate: any;
  crfResArray: CrfResponse[] = [];
  Form: FormGroup;

  constructor(private apiService: ApiService, private formBuilder: FormBuilder,
    private route: Router) {
  }
  ngOnInit() {
    // this.Form = this.formBuilder.group({
    //   remarks: [null, Validators.required],
    // });
    let loginResoutputdata = JSON.parse(sessionStorage.getItem("logindatas") || '{}');
    this.crfVisitreq.userId = loginResoutputdata.result.userId;
    let studydetailsdata = JSON.parse(sessionStorage.getItem("studydetails") || '{}');
    this.protocolId = studydetailsdata.protocolNumber;
    this.crfVisitreq.studyId = studydetailsdata.studyId;
    let singlepatientfulldata = JSON.parse(sessionStorage.getItem("singlepatientfulldata") || '{}');
    this.crfVisitreq.patientId = singlepatientfulldata.patientId;
    this.registerdate = singlepatientfulldata.registeredDate;
    this.apiService.crfmethod(this.crfVisitreq)
      .subscribe(crfdata => {
        //console.log(crfdata)
        for (let i = 0; i < crfdata.result.length; i++) {
          //console.log(crfdata.result.length);
          var crfRes = new CrfResponse();
          this.crfdatalength = crfdata.result.length;
          crfRes.studyId = crfdata.result[i].studyId
          crfRes.visitId = crfdata.result[i].visitId
          crfRes.patientId = crfdata.result[i].patientId
          crfRes.userStatus = crfdata.result[i].userStatus
          crfRes.sectionId = crfdata.result[i].sectionId
          crfRes.visitName = crfdata.result[i].visitName
          crfRes.categoryId = crfdata.result[i].categoryId
          crfRes.doctorStatus = crfdata.result[i].doctorStatus
          this.crfResArray.push(crfRes);
          // console.log(this.crfResArray);
        }
      })
  }
  onChange(e: any) {
    //console.log(e)
    sessionStorage.setItem('Crfpatientsingledata', JSON.stringify(e));
    //if status is open for all forms
    if (e.userStatus == 'OPEN') {
      this.route.navigate(['crfvisitquestions']);
      //if status is submitted only Demographic
    } else if (e.visitName == 'Demographic' && e.userStatus == 'SUBMITTED') {
      this.route.navigate(['crfvisitDemographic']);
      //if status is submitted all forms expect Demographic
    } else if ((e.userStatus == 'SUBMITTED') && (e.visitName == 'Medical History' || 'Medication' || 'Prior Non drug therapies/procedures'))
      this.route.navigate(['CrfVisitSubmited'])
  }

}
