import { Component, OnInit } from '@angular/core';
import { FormSubmittedModel } from '../formsforsumittedmodel';
import { ApiService } from '../api.service';
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { UpdateVisitModel } from '../updatevisitmodel';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-patentdairyupdateform',
  templateUrl: './patentdairyupdateform.component.html',
  styleUrls: ['./patentdairyupdateform.component.css']
})
export class PatentdairyupdateformComponent implements OnInit {
  formSubmittedModel = new FormSubmittedModel();
  updateVisitModel = new UpdateVisitModel();
  thisIsMyForm: FormGroup;
  section: any;
  patientdairy: any;
  visitName: any
  array: any;
  crfarray: any = [];
  maxLength: any;
  constructor(private datePipe: DatePipe, private formBuilder: FormBuilder, private route: Router, private apiService: ApiService,) {
    this.thisIsMyForm = new FormGroup({
      formArrayName: this.formBuilder.array([], Validators.required)
    })
  }
  ngOnInit() {
    let patientdairytittle = JSON.parse(sessionStorage.getItem("patientdairytittle") || '{}');
    this.patientdairy = patientdairytittle;
    //console.log(patientdairytittle)
    let loginResoutputdata = JSON.parse(sessionStorage.getItem("logindatas") || '{}');
    let singlepatientfulldata = JSON.parse(sessionStorage.getItem("singlepatientfulldata") || '{}');
    this.formSubmittedModel.userId = loginResoutputdata.result.userId;
    this.formSubmittedModel.patientId = singlepatientfulldata.patientId;
    let patientdairysectionId = JSON.parse(sessionStorage.getItem("patientdairysectionId") || '{}');
    this.formSubmittedModel.categoryId = patientdairysectionId;
    this.apiService.submitstatusmethod(this.formSubmittedModel)
      .subscribe(crfopenstatusdata => {
        // console.log(crfopenstatusdata)
        this.section = crfopenstatusdata.result.questions;
        //  console.log(this.section);
        this.buildForm();
      });
  }

  buildForm() {
    const controlArray = this.thisIsMyForm.get('formArrayName') as FormArray;
    let singlepatientfulldata = JSON.parse(sessionStorage.getItem("singlepatientfulldata") || '{}');
    let participaintsectionId = JSON.parse(sessionStorage.getItem("participaintsectionId") || '{}');
    let loginResoutputdata = JSON.parse(sessionStorage.getItem("logindatas") || '{}');
    let medicalList = JSON.parse(sessionStorage.getItem("participantdairy") || '{}');
    // console.log("Data" + this.section);
    Object.keys(this.section).forEach((i) => {
      // console.log(this.section[i]);
      controlArray.push(
        this.formBuilder.group({
          answer: this.section[i].answer,
          questionId: this.section[i].questionId,
          categoryId: medicalList.sectionId,
          questionTitle: this.section[i].questionTitle,
          patientId: singlepatientfulldata.patientId,
          userId: loginResoutputdata.result.userId,
          sectionId: participaintsectionId,
          remarks: "",
        })
      )
    })
    //console.log(controlArray.controls)
  }
  //length validations
  onKeypressEvent(x, event) {
    this.maxLength = x.length;
    if (event.target.value.length == this.maxLength) {
      return false;
    } else {
      return true;
    }
  }

  equal() {
    this.crfarray = [];
    if (this.thisIsMyForm.valid) {
      this.array = this.thisIsMyForm.value;
      //console.log(this.array.formArrayName)
      for (let datum of this.array.formArrayName) {
        if (typeof (datum.answer) !== 'undefined') {
          if (datum.answer.toString().includes("GMT")) {
            datum.answer = this.datePipe.transform(datum.answer, 'yyyy-MM-dd')
          }
          datum.answer = datum.answer.toString();
          this.crfarray.push(datum);  // add data into array
        }
      }
      console.log(this.crfarray)
      this.apiService.statussubmittedmethod(this.crfarray)
        .subscribe(visitdata => {
          console.log(visitdata)
          this.route.navigate(['updateparticipantdiarydetails']);
        })

    } else {
      alert("Please fill all the mandatory fields")
      // console.log('Not Submit')
    }
  }
}