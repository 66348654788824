import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { Router } from "@angular/router";
import { DatePipe } from '@angular/common';
import { UpadteDataModel } from '../updatedatamodel';

@Component({
  selector: 'app-patientdairydetailssubmitform',
  templateUrl: './patientdairydetailssubmitform.component.html',
  styleUrls: ['./patientdairydetailssubmitform.component.css']
})
export class PatientdairydetailssubmitformComponent implements OnInit {
  visitName: any;
  upadteDataModel = new UpadteDataModel();
  CrfSubmitted: any;
  title: any;
  sectionId: any;
  show: any;
  dayclickeddate: any;
  constructor( private apiService: ApiService, private route: Router, private datePipe: DatePipe) {
  }
  ngOnInit() {
    let dayclickeddate = JSON.parse(sessionStorage.getItem("dayclickeddate") || '{}');
    this.dayclickeddate = this.datePipe.transform(new Date(dayclickeddate), "yyyy-MM-dd");
    //  console.log( this.dayclickeddate);
    let singlepatientfulldata = JSON.parse(sessionStorage.getItem("singlepatientfulldata") || '{}');
    let loginResoutputdata = JSON.parse(sessionStorage.getItem("logindatas") || '{}');
    let participaintsectionId = JSON.parse(sessionStorage.getItem("participaintsectionId") || '{}')
    let Crfpatientsingledata = JSON.parse(sessionStorage.getItem("Crfpatientsingledata") || '{}');
    this.upadteDataModel.patientId = singlepatientfulldata.patientId;
    this.upadteDataModel.sectionId = participaintsectionId;
    this.upadteDataModel.userId = loginResoutputdata.result.userId;
    this.upadteDataModel.fromDate = this.dayclickeddate;
    this.upadteDataModel.toDate = this.dayclickeddate;
    this.visitName = Crfpatientsingledata.visitName;
    this.apiService.updateSubmitmethod(this.upadteDataModel)
      .subscribe(crfSubmitteddata => {
        // console.log(crfSubmitteddata)
        for (let index = 0; index < crfSubmitteddata.result.length; index++) {
          this.sectionId = crfSubmitteddata.result[index].sectionId;
          sessionStorage.setItem('patientdairysectionId', JSON.stringify(this.sectionId));
          this.title = crfSubmitteddata.result[index].title;
          sessionStorage.setItem('patientdairytittle', JSON.stringify(this.title));
          if (this.title == 'Adverse Events' || this.title == 'Concomitant Medication') {
            this.show = true;
          }
        }
        this.CrfSubmitted = crfSubmitteddata.result;
      });
  }
  getdetailsId(e) {
    sessionStorage.setItem('participantdairy', JSON.stringify(e));
  }
 
}
