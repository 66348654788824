import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from '../api.service';
import { StudyModelRequest } from '../studymodelrequest';
import { Inclusion_Exclusiondata } from "../inclusion_exclusiondataresponse";
@Component({
  selector: 'app-inclusion-exclusiondata',
  templateUrl: './inclusion-exclusiondata.component.html',
  styleUrls: ['./inclusion-exclusiondata.component.css']
})
export class InclusionExclusiondataComponent implements OnInit {
  studyModelReq = new StudyModelRequest();
  inclusion_Exclusiondata = new Inclusion_Exclusiondata()
  checkboxescount: number = 0; //checkboxes all are selected count
  toggleBool: boolean = true;
  constructor( private apiService: ApiService, private route: Router) {
   
  }

  ngOnInit() {
    let studydetailsdata = JSON.parse(sessionStorage.getItem("studydetails") || '{}');
    let logiResoutputdata = JSON.parse(sessionStorage.getItem("logindatas") || '{}');
    this.studyModelReq.studyId = studydetailsdata.studyId;
    this.studyModelReq.userId = logiResoutputdata.result.userId
    this.apiService.inclusion_exclusiondatamethod(this.studyModelReq)
      .subscribe(Inclusion_Exclusiondata => {
        // console.log(Inclusion_Exclusiondata);
        this.inclusion_Exclusiondata = Inclusion_Exclusiondata.result;
        // sessionStorage.setItem('paticipantlength',JSON.stringify(this.participantRess));
      });
  }
  //checkboxes method
  changed(item: any) {
    console.log(item.checked)
    if (item.checked == true) {
      this.checkboxescount = this.checkboxescount + 1;
      if (this.checkboxescount == this.inclusion_Exclusiondata.exclusionList.length + this.inclusion_Exclusiondata.inclusionList.length) {
        this.toggleBool = false;
      }
    } else {
      this.checkboxescount = this.checkboxescount - 1;
      this.toggleBool = true;
      // console.log("button is deactive")
    }
  }

}
