import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from "@angular/router";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../api.service';
import {
  CalendarEvent,
  // CalendarEventAction,
  // CalendarEventTimesChangedEvent,
  CalendarMonthViewDay,
  CalendarView,
} from 'angular-calendar';
import { Subject } from 'rxjs';
import { PatientDairyModel } from '../patientdairymodel';

@Component({
  selector: 'app-patient-dairy',
  templateUrl: './patient-dairy.component.html',
  styleUrls: ['./patient-dairy.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class PatientDairyComponent implements OnInit {

  patientDairyModel = new PatientDairyModel();
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  startday = '01';
  endday = '31';
  dates: any
  eventtilte: any;
  patientId: any
  startdate: any;
  enddate: any;
  fromdate: any;
  lastdate: any;
  date: Date;
  maxdate: any;
  dynamicdata: any;
  dynamicdate: any;
  adverseEvents: any;
  dynamicmonth: any;
  registerdate: any;
  patientDuration: any;
  adverse: any = [];
  showLabel: any;
  red: any;//reddays
  showlabel1: any;
  condtion: any;
  constructor(private route: Router, private modal: NgbModal, private apiSevice: ApiService) {
  }
  ngOnInit() {
    let singlepatientfulldata = JSON.parse(sessionStorage.getItem("singlepatientfulldata") || '{}');
    this.fromdate = singlepatientfulldata.registeredDate;
    ///new month and year dynamic method
    this.dates = new Date().getFullYear() + '-' + (new Date().getMonth() + 1);
    // console.log(this.dates)
    this.startdate = this.dates + '-' + this.startday
    this.enddate = this.dates + '-' + this.endday
    let loginResoutputdata = JSON.parse(sessionStorage.getItem("logindatas") || '{}');
    let Crfpatientsingledata = JSON.parse(sessionStorage.getItem("Crfpatientsingledata") || '{}');
    this.patientId = singlepatientfulldata.patientId;
    //console.log(this.patientId)
    this.patientDairyModel.patientId = singlepatientfulldata.patientId;
    this.patientDairyModel.studyId = Crfpatientsingledata.studyId;
    this.patientDairyModel.userId = loginResoutputdata.result.userId;
    this.patientDairyModel.fromDate = this.startdate;
    this.patientDairyModel.toDate = this.enddate;
    this.apiSevice.patientdairymethod(this.patientDairyModel)
      .subscribe(participantDaiarydata => {
        this.adverseEvents = participantDaiarydata.result;
        for (let datum of this.adverseEvents) {
          this.adverse.push(datum.createdDate);
          sessionStorage.setItem('participantdairydata', JSON.stringify(participantDaiarydata));
        }
        this.refresh.next();
      })
  }
  // single date clicked
  dayClicked(e: any) {
    //console.log(e.date);
    let patientDuration = JSON.parse(sessionStorage.getItem("studydetails") || '{}');
    //console.log(patientDuration.patientDuration)
    let singlepatientfulldata = JSON.parse(sessionStorage.getItem("singlepatientfulldata") || '{}');
    this.date = new Date(singlepatientfulldata.registeredDate);
    // console.log("pp" + this.date)
    this.date.setDate(this.date.getDate() + patientDuration.patientDuration);
    let data4 = this.date;
   // console.log(singlepatientfulldata.registeredDate)
  //  console.log(data4)
    if (e.date) {
      this.showlabel1 = true;
    } else {
      this.showlabel1 = false;
    }
    if (e.date <= data4) {
      this.showLabel = true;
    } else {
      this.showLabel = false;
    }
    let data = new Date(e.date);
    this.dynamicmonth = data;
    //console.log(this.dynamicmonth)
    sessionStorage.setItem('dayclickeddate', JSON.stringify(this.dynamicmonth));

    //console.log("li" + this.dynamicmonth)
    //console.log(new Date())
    this.dynamicdate = data.getDate();
    //console.log("hii" + this.dynamicdate)
    //dynamic day add to dynamic year
    this.dates = new Date().getFullYear() + '-' + (new Date().getMonth() + 1);
    this.dynamicdata = this.dates + '-' + this.dynamicdate;
    // console.log(this.dynamicdata)
    let loginResoutputdata = JSON.parse(sessionStorage.getItem("logindatas") || '{}');
    let Crfpatientsingledata = JSON.parse(sessionStorage.getItem("Crfpatientsingledata") || '{}');
    this.patientDairyModel.patientId = Crfpatientsingledata.patientId;
    this.patientDairyModel.studyId = Crfpatientsingledata.studyId;
    this.patientDairyModel.userId = loginResoutputdata.result.userId;
    this.patientDairyModel.fromDate = this.dynamicdata;
    this.patientDairyModel.toDate = this.dynamicdata;
    this.apiSevice.patientdairymethod(this.patientDairyModel)
      .subscribe(participantDaiarydatasingledate => {
        //console.log(participantDaiarydatasingledate);
      })
  }
  refresh: Subject<any> = new Subject();
  events: CalendarEvent[] = [];
  activeDayIsOpen: boolean = true;
  // handleEvent(action: string, event: CalendarEvent): void {
  // }
  //adverseevents
  dateIsAdverse(date: Date): boolean {
    var typecheck = "";
    // console.log("Data" + this.adverse);
    for (let datum of this.adverse) {
      if (date.toLocaleDateString() == new Date(datum).toLocaleDateString()) {
        typecheck = typecheck + "Yes";
      }
    }
    //console.log("Status"+typecheck)
    if (typecheck.includes("Yes")) {
      // console.log(typecheck)
      return true;
    }
  }
  //yello days
  dateIsRed(date: Date): boolean {
    let patientDuration = JSON.parse(sessionStorage.getItem("studydetails") || '{}');
    //console.log(patientDuration.patientDuration)
    let singlepatientfulldata = JSON.parse(sessionStorage.getItem("singlepatientfulldata") || '{}');
    this.date = new Date(singlepatientfulldata.registeredDate);
    // console.log("pp" + this.date)
    this.date.setDate(this.date.getDate() + patientDuration.patientDuration);
    let data4 = this.date;
    //console.log("pp" + data4)
    this.condtion = data4;
    return date.getTime() <= data4.getTime();
  }
  //yello days
  dateIsGray(date: Date): boolean {
    let patientDuration = JSON.parse(sessionStorage.getItem("studydetails") || '{}');
    //console.log(patientDuration.patientDuration)
    let singlepatientfulldata = JSON.parse(sessionStorage.getItem("singlepatientfulldata") || '{}');
    this.date = new Date(singlepatientfulldata.registeredDate);
    // console.log("pp" + this.date)
    this.date.setDate(this.date.getDate() + patientDuration.patientDuration);
    let data4 = this.date;
    //console.log("pp" + data4)
    this.condtion = data4;
    return date.getTime() >= data4.getTime();
  }
  //day show maxium method
  dateIsMax(date: Date): boolean {
    let singlepatientfulldata = JSON.parse(sessionStorage.getItem("singlepatientfulldata") || '{}');
    var date1 = new Date(singlepatientfulldata.registeredDate);
    var date2 = new Date();
    var Time = date2.getTime() - date1.getTime();
    var Days = Time / (1000 * 3600 * 24);
    let data = Math.round(Days)
    this.date = new Date(singlepatientfulldata.registeredDate);
    this.date.setDate(this.date.getDate() + data);
    this.maxdate = this.date;
    return date.getTime() >= this.maxdate.getTime();
  }
  //day show minimum
  dateIsMin(date: Date): boolean {
    let singlepatientfulldata = JSON.parse(sessionStorage.getItem("singlepatientfulldata") || '{}');
    this.registerdate = new Date(singlepatientfulldata.registeredDate);
    this.registerdate.setDate(this.registerdate.getDate() - 1);
    return date.getTime() >= new Date(this.registerdate).getTime();
  }
  //days colors in max and mini
  applyDateSelectionPolicy({ body }: { body: CalendarMonthViewDay[] }): void {
    body.forEach(day => {
      //registeration date before days
      if (!this.dateIsMin(day.date)) {
        //day.backgroundColor = "#D3D3D3";//gray
        day.cssClass = 'gray';
      }
      else if (!this.dateIsMax(day.date)) {
        //registeration to current date between adverse events
        if (this.dateIsAdverse(day.date)) {
          day.cssClass = 'dotg';
        } else if (this.dateIsGray(day.date)) {
          //registeration+patiet duration afterdays
          // day.backgroundColor = "#D3D3D3"; //gary color
          day.cssClass = 'gray';
        }
        else {
          //registeration and current date between days
          day.cssClass = 'dot';
        }
      }
      //registeration+patiet duration
      else if (this.dateIsRed(day.date)) {
        //day.backgroundColor = "#FFFF00"; //yellow color
        day.cssClass = 'doty';
      }
      else {
        //registeration+patiet duration afterdays
        // day.backgroundColor = "#D3D3D3"; //gary color
        day.cssClass = 'gray';
      }
    });
  }
  closeOpenMonthViewDay() {
    //console.log("lop"+this.viewDate)
    this.activeDayIsOpen = false;
    let singlepatientfulldata = JSON.parse(sessionStorage.getItem("singlepatientfulldata") || '{}');
    this.fromdate = singlepatientfulldata.registeredDate;
    this.dates = new Date(this.viewDate).getFullYear() + '-' + (new Date(this.viewDate).getMonth() + 1);
    // console.log(this.dates)
    this.startdate = this.dates + '-' + this.startday
    this.enddate = this.dates + '-' + this.endday
    // participant dairy to call api for set the data
    let loginResoutputdata = JSON.parse(sessionStorage.getItem("logindatas") || '{}');
    let Crfpatientsingledata = JSON.parse(sessionStorage.getItem("Crfpatientsingledata") || '{}');
    this.patientId = singlepatientfulldata.patientId;
    //console.log(this.patientId)
    this.patientDairyModel.patientId = singlepatientfulldata.patientId;
    this.patientDairyModel.studyId = Crfpatientsingledata.studyId;
    this.patientDairyModel.userId = loginResoutputdata.result.userId;
    this.patientDairyModel.fromDate = this.startdate;
    this.patientDairyModel.toDate = this.enddate;
    this.apiSevice.patientdairymethod(this.patientDairyModel)
      .subscribe(participantDaiarydata => {
        // console.log(participantDaiarydata);
        // for(let datum of participantDaiarydata.result){
        this.adverseEvents = participantDaiarydata.result;
        for (let datum of this.adverseEvents) {
          this.adverse.push(datum.createdDate);
        }
        this.refresh.next();
      })
  }
 
}