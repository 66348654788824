import { Component, OnInit , AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { ApiService } from '../api.service';
import { StudyModelRequest } from '../studymodelrequest';
import { StudyModelResponse } from '../studymodelresponse';
import { Router } from "@angular/router";
//import { TranslateService } from '@ngx-translate/core';
// import { GoogletranslateService } from '../GoogletranslateService';
// import { GoogleObj } from '../google';
import { HttpUrlEncodingCodec } from '@angular/common/http';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  studyDocumentsimg = '../assets/images/studydocuments.png';
  patientimg = '../assets/images/healthMonitoring.png';
  health = '../assets/images/queries.png'
  logindisplayName: String;
  loginPicture: String;
  studyModelRequest = new StudyModelRequest();
  studyModelRes = new StudyModelResponse();
  showLabel: any;
  loginName: any;
  ngAfterContentChecked(): void {
    this.changesDetect.detectChanges();
  }
  constructor(private apiSevice: ApiService, private route: Router, private changesDetect: ChangeDetectorRef) {
  }
  ngOnInit() {
    let logiResoutputdata = JSON.parse(sessionStorage.getItem("logindatas") || '{}');
    if (logiResoutputdata.result.roleId == '3') {
      this.loginName = "INVESTIGATOR";
    }
    else if (logiResoutputdata.result.roleId == '4') {
      this.loginName = "CO-INVESTIGATOR";
    }
    else if (logiResoutputdata.result.roleId == '5') {
      this.loginName = "CO-ORDINATOR";
    }
    this.logindisplayName = logiResoutputdata.result.displayName;
    this.loginPicture = logiResoutputdata.result.displayPictureUrl;
    // study response api and  get study details 
    this.studyModelRequest.userId = logiResoutputdata.result.userId;
    this.studyModelRequest.studyId = logiResoutputdata.result.otherId;
    this.apiSevice.studyDetailMethod(this.studyModelRequest)
      .subscribe(studyModelResdata => {
        // console.log(studyModelResdata);
        //for display the data study details
        this.studyModelRes = studyModelResdata.result;
        //console.log(this.studyModelRes.contactMobile)
        sessionStorage.setItem('studydetails', JSON.stringify(this.studyModelRes))
        //condition for status
        if (this.studyModelRes.studyStatus == 'OPEN') {
          this.showLabel = true;
        } else {
          this.showLabel = false;
        }
      })
  }
  //roter method
  routermethod(e: any) {
    // console.log(e)
     if (e == 'study') {
       this.route.navigate(['studydocuments']);
     } else if (e == 'patient') {
       this.route.navigate(['participantstatus']);
     } else if (e == 'queries') {
       this.route.navigate(['querieslist']);
     }
   }
}