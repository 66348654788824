import { Component, OnInit, Input } from '@angular/core';
import { CrfQuestionsRequest } from '../crfquestionsrequest';
import { ApiService } from '../api.service';
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { SubmitVisitModel } from '../submitvisitmodel';
import { Location, DatePipe } from '@angular/common'

@Component({
  selector: 'app-crfvisitquestions',
  templateUrl: './crfvisitquestions.component.html',
  styleUrls: ['./crfvisitquestions.component.css']
})
export class CrfvisitquestionsComponent implements OnInit {
  thisIsMyForm: FormGroup;
  section: any;
  CrfQuestionsReq = new CrfQuestionsRequest();
  submitVisitModel = new SubmitVisitModel();
  visitName: any;
  array: any;
  crfResArray: any;
  crfarray: any = [];
  maxLenght: any;
  age:any;
    //today's date show for calender
    todayDate: Date = new Date();
  constructor(private datePipe: DatePipe,private formBuilder: FormBuilder, private route: Router, private apiService: ApiService,) {
    this.thisIsMyForm = new FormGroup({
      formArrayName: this.formBuilder.array([],Validators.required)
    })
    //console.log(this.thisIsMyForm);
  }
  ngOnInit() {
    let loginResoutputdata = JSON.parse(sessionStorage.getItem("logindatas") || '{}');
    let Crfpatientsingledata = JSON.parse(sessionStorage.getItem("Crfpatientsingledata") || '{}');
    this.CrfQuestionsReq.userId = loginResoutputdata.result.userId;
    this.CrfQuestionsReq.sectionId = Crfpatientsingledata.sectionId;
    this.visitName = Crfpatientsingledata.visitName;
    this.apiService.crfstatusopenmethod(this.CrfQuestionsReq)
      .subscribe(crfopenstatusdata => {
        //  console.log(crfopenstatusdata)
        this.section = crfopenstatusdata.result;
          console.log(this.section);
        this.buildForm();
      });
  }
  buildForm() {
    let crfpatientfulldata = JSON.parse(sessionStorage.getItem("Crfpatientsingledata") || '{}');
    let loginResoutputdata = JSON.parse(sessionStorage.getItem("logindatas") || '{}');
    let singlepatientfulldata = JSON.parse(sessionStorage.getItem("singlepatientfulldata") || '{}');
    const controlArray = this.thisIsMyForm.get('formArrayName') as FormArray;
    //console.log("Data" + this.section);
    Object.keys(this.section).forEach((i) => {
      if(this.section[i].sectionId==1){
      if (this.section[i].questionTitle == "Gender") {
        this.section[i].answer = singlepatientfulldata.gender;
      }
      else if (this.section[i].questionTitle == "Date of Birth") {
        this.section[i].answer = singlepatientfulldata.dob;
      }
      else if (this.section[i].questionTitle == "Age") {
        this.section[i].answer = singlepatientfulldata.age;
      }
      console.log(this.section[i].sectionId)

      controlArray.push(
        this.formBuilder.group({
          answer:this.section[i].answer,
          questionId: this.section[i].questionId,
          sectionId: this.section[i].sectionId,
          visitId: crfpatientfulldata.visitId,
          patientId: crfpatientfulldata.patientId,
          studyId: crfpatientfulldata.studyId,
          remarks: "",
          userId: loginResoutputdata.result.userId,
        })
      )
      }else{
        controlArray.push(
          this.formBuilder.group({
            answer:[''],
            questionId: this.section[i].questionId,
            sectionId: this.section[i].sectionId,
            visitId: crfpatientfulldata.visitId,
            patientId: crfpatientfulldata.patientId,
            studyId: crfpatientfulldata.studyId,
            remarks: "",
            userId: loginResoutputdata.result.userId,
          })
        )
      }

    })
    // console.log(controlArray.controls)
  }
  equal() {
    this.crfarray = [];
    if (this.thisIsMyForm.valid) {
      this.array = this.thisIsMyForm.value;
      //console.log(this.array.formArrayName)
      for (let datum of this.array.formArrayName) {
        console.log(datum)
        if (typeof (datum.answer) !== 'undefined') {
          // if (datum.questionTitle == "Age") {
          //   datum.answer = this.age;
          // }
          for (let index = 0; index < this.section.length; index++) {
            if ((this.section[index].questionTitle == "Age") && (this.section[index].questionId ==datum.questionId)) {
              datum.answer = this.age;
            }
          }
          if (datum.answer.toString().includes("GMT")) {
            datum.answer = this.datePipe.transform(datum.answer, 'yyyy-MM-dd')
          }
          datum.answer = datum.answer.toString();
          this.crfarray.push(datum);  // add data into array
        }
      }
      console.log(this.crfarray)
      this.apiService.statusopenmethod(this.crfarray)
      .subscribe(visitdata => {
        // console.log(visitdata)
        this.route.navigate(['crf']);
      })
    } else {
      alert("Please fill all the mandatory fields")
      // console.log('Not Submit')
    }
  }
  //length validations
  onKeypressEvent(x, event) {
    // console.log(x)
    this.maxLenght = x.length;
    if (event.target.value.length == this.maxLenght) {
      return false;
    }
  }
 
  onDateChange(x:any,$event:any){
    console.log($event.value)
    let patientDateStrF = new Date($event.value)
    console.log(new Date().getFullYear()-patientDateStrF.getFullYear())
    this.age=new Date().getFullYear()-patientDateStrF.getFullYear();
    if (x.questionTitle=="Date of Birth") {
      for (let index = 0; index < this.section.length; index++) {
        if (this.section[index].questionTitle == "Age") {
          this.section[index].answer = this.age;
        }
      }
    }
  }
  BmiMethod() {
    alert("This Field is automatically filled")
  }
}
