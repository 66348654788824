import { Component, OnInit } from '@angular/core';
import { UpdateQuery } from '../updatequery';
import { ApiService } from '../api.service';
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
@Component({
  selector: 'app-updatequery',
  templateUrl: './updatequery.component.html',
  styleUrls: ['./updatequery.component.css']
})
export class UpdatequeryComponent implements OnInit {
  updateQuery = new UpdateQuery();
  section: any;
  form: FormGroup;
  options: any;
  constructor(private apiService: ApiService, private route: Router, private formBuilder: FormBuilder) {
  }
  ngOnInit() {
    this.form = this.formBuilder.group({
      answer: [null, Validators.required],
      remarks: [null],
    });
    let queriesdetails = JSON.parse(sessionStorage.getItem("querydetails") || '{}');
    this.section = queriesdetails;
    console.log(this.section)
    if (this.section.questionType == 'SELECT-MULTI' || this.section.questionType == 'SELECT') {
      this.options = this.section.questionOptions.split(",")
    }
  }
  onSubmit() {
    let logiResoutputdata = JSON.parse(sessionStorage.getItem("logindatas") || '{}');
    let queriesdetails = JSON.parse(sessionStorage.getItem("querydetails") || '{}');
    if (this.form.valid) {
      this.updateQuery.detailId = queriesdetails.detailId;
      this.updateQuery.queryId = queriesdetails.queryId;
      this.updateQuery.updatedUserId = logiResoutputdata.result.userId;
      this.updateQuery.comments = this.form.value.remarks;
      this.updateQuery.modifiedAnswer = this.form.value.answer;
      this.updateQuery.status = queriesdetails.status;
      console.log(this.form.value.answer)
      this.apiService.updatequerymethod(this.updateQuery)
        .subscribe(updatequerydata => {
          // console.log(updatequerydata)
          this.route.navigate(['querydetails']);
        });
    } else {
      alert("Please fill all the mandatory fields")
      // console.log('Not Submit')
    }
  }
 
}
