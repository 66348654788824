import { Component, OnInit } from '@angular/core';
import { CrfQuestionsRequest } from '../crfquestionsrequest';
import { ApiService } from '../api.service';
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { SubmitVisitModel } from '../submitvisitmodel';
//import { TranslateService } from '@ngx-translate/core';
import { Location, DatePipe } from '@angular/common'

@Component({
  selector: 'app-addnewmedicalform',
  templateUrl: './addnewmedicalform.component.html',
  styleUrls: ['./addnewmedicalform.component.css']
})
export class AddnewmedicalformComponent implements OnInit {
  thisIsMyForm: FormGroup;
  section: any;//questions for build form
  CrfQuestionsReq = new CrfQuestionsRequest();
  visitName: any;
  array: any;
  crfResArray: any;
  crfarray: any = [];
  maxLength: any;
  //today's date show for calender
  todayDate: Date = new Date();
  constructor(private datePipe: DatePipe, private formBuilder: FormBuilder, private route: Router, private apiService: ApiService,) {
    this.thisIsMyForm = new FormGroup({
      formArrayName: this.formBuilder.array([], Validators.required)
    })
  }
  ngOnInit() {
    let loginResoutputdata = JSON.parse(sessionStorage.getItem("logindatas") || '{}');
    let Crfpatientsingledata = JSON.parse(sessionStorage.getItem("Crfpatientsingledata") || '{}');
    this.CrfQuestionsReq.userId = loginResoutputdata.result.userId;
    this.CrfQuestionsReq.sectionId = Crfpatientsingledata.sectionId;
    this.visitName = Crfpatientsingledata.visitName;
    this.apiService.crfstatusopenmethod(this.CrfQuestionsReq)
      .subscribe(crfopenstatusdata => {
        // console.log(crfopenstatusdata)
        this.section = crfopenstatusdata.result;
        console.log(this.section);
        this.buildForm();
      });
  }
  buildForm() {
    let crfpatientfulldata = JSON.parse(sessionStorage.getItem("Crfpatientsingledata") || '{}');
    let loginResoutputdata = JSON.parse(sessionStorage.getItem("logindatas") || '{}');
    const controlArray = this.thisIsMyForm.get('formArrayName') as FormArray;
    // console.log("Data" + this.section);
    Object.keys(this.section).forEach((i) => {
      //console.log(this.section[i]);
      controlArray.push(
        this.formBuilder.group({
          answer: [''],
          questionId: this.section[i].questionId,
          sectionId: this.section[i].sectionId,
          visitId: crfpatientfulldata.visitId,
          patientId: crfpatientfulldata.patientId,
          studyId: crfpatientfulldata.studyId,
          remarks: "",
          userId: loginResoutputdata.result.userId,
        })
      )
    })
    // console.log(controlArray.controls)
  }
  equal() {
    this.crfarray = [];
    if (this.thisIsMyForm.valid) {
      this.array = this.thisIsMyForm.value;
      //console.log(this.array.formArrayName)
      for (let datum of this.array.formArrayName) {
        if (typeof (datum.answer) !== 'undefined') {
          if (datum.answer.toString().includes("GMT")) {
            datum.answer = this.datePipe.transform(datum.answer, 'yyyy-MM-dd')
          }
          datum.answer = datum.answer.toString();
          this.crfarray.push(datum);  // add data into array
        }
      }
      console.log(this.crfarray)
      this.apiService.statusopenmethod(this.crfarray)
        .subscribe(visitdata => {
          // console.log(visitdata)
          this.route.navigate(['CrfVisitSubmited']);
        })
    } else {
      alert("Please fill all the mandatory fields")
      // console.log('Not Submit')
    }
  }
  //form validations for limit text or number
  onKeypressEvent(x, event) {
    this.maxLength = x.length;
    if (event.target.value.length == this.maxLength) {
      return false;
    }
  }


}
