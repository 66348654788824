export class CrfResponse {

    "createdDate": Number;
    "doctorStatus": String;
    "fromDay": Number;
    "patientId": String;
    "sectionId": Number;
    "studyId": Number;
    "toDay": Number;
    "userStatus": String;
    "visitId": Number;
    "visitName": String;
    "categoryId": any
}