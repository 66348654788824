import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { ApiService } from '../api.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscriber } from "rxjs";
import { UpdateLabReportModel } from '../updatelabreportmodelrequest';
@Component({
  selector: 'app-updatelabreport',
  templateUrl: './updatelabreport.component.html',
  styleUrls: ['./updatelabreport.component.css']
})
export class UpdatelabreportComponent implements OnInit {
  updateLabReportModel = new UpdateLabReportModel()
  Form: FormGroup;
  base64textString: any
  base64result: any;
  constructor( private apiService: ApiService, private route: Router, private formBuilder: FormBuilder,) {
  }
  ngOnInit() {
    let patientId = JSON.parse(sessionStorage.getItem("datass") || '{}');
    //console.log(patientId)
    this.Form = this.formBuilder.group({
      testvalue: [null, Validators.required],
      image: [null]
    })
  }
  onChange(evt: any) {
    const file = evt.target.files[0];
      // this.convertToBase64(file);
      if ((file.type == "application/pdf" )|| (file.type =="image/jpg") ||(file.type =="image/png")) {
        console.log(file.type)
        this.convertToBase64(file);
      }else{
        alert("Please Upload Only (PDF Or JPG ,PNG Images only)")
      }
  }
  convertToBase64(file: File) {
    const observable = new Observable((subscriber: Subscriber<any>) => {
      this.readFile(file, subscriber);
    });
    observable.subscribe((d) => {
      this.base64textString = d;
      this.base64result = this.base64textString.split(',')[1];
      //  console.log(this.base64result);
    });
  }
  readFile(file: File, subscriber: Subscriber<any>) {
    const filereader = new FileReader();
    filereader.readAsDataURL(file);
    filereader.onload = () => {
      subscriber.next(filereader.result);
      subscriber.complete();
    };
    filereader.onerror = (error) => {
      subscriber.error(error);
      subscriber.complete();
    }
  }
  onSubmit() {
    if(this.Form.valid){
      if(this.base64result!=null){
    let logiResoutputdata = JSON.parse(sessionStorage.getItem("logindatas") || '{}');
    let studydetails = JSON.parse(sessionStorage.getItem("studydetails") || '{}');
    this.updateLabReportModel.studyId = studydetails.studyId;
    this.updateLabReportModel.userId = logiResoutputdata.result.userId;
    this.updateLabReportModel.dayNumber = 1;
    let singlepatientfulldata = JSON.parse(sessionStorage.getItem("singlepatientfulldata") || '{}');
    this.updateLabReportModel.patientId = singlepatientfulldata.patientId;
    this.updateLabReportModel.mobileNumber = singlepatientfulldata.mobileNumber;
    this.updateLabReportModel.testName = "Covid";
    this.updateLabReportModel.testValue = this.Form.value.testvalue;
    this.updateLabReportModel.fileURL = this.base64result;
    console.log(this.updateLabReportModel)
    this.apiService.updatelabreportmethod(this.updateLabReportModel)
      .subscribe(updatelabreportdata => {
        //   console.log(updatelabreportdata)
        this.route.navigate(['participantstatus']);
      });
    }else{
      alert("Please Upload Only (PDF Or JPG ,PNG Images only)")

    }
    } else {
      alert("Please fill all the mandatory fields")
      // console.log('Not Submit')
    }
  }
  logout() {
    // remove user from session storage to log user out
    sessionStorage.removeItem('datas');
    this.route.navigate(['login']);
  }
}
