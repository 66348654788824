import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { ApiService } from '../api.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscriber } from "rxjs";
import { ApproveRequest } from '../approverequest';

@Component({
  selector: 'app-approvepatient',
  templateUrl: './approvepatient.component.html',
  styleUrls: ['./approvepatient.component.css']
})
export class ApprovepatientComponent implements OnInit {
  approveRequest = new ApproveRequest()
  Form: FormGroup;
  constructor(private apiService: ApiService, private route: Router, private formBuilder: FormBuilder,) {

  }
  ngOnInit() {
    this.Form = this.formBuilder.group({
      testvalue: [null, Validators.required]
    })
  }
  onSubmit() {
    if (this.Form.valid) {
      let logiResoutputdata = JSON.parse(sessionStorage.getItem("logindatas") || '{}');
      let studydetails = JSON.parse(sessionStorage.getItem("studydetails") || '{}');
      this.approveRequest.studyId = studydetails.studyId;
      this.approveRequest.userId = logiResoutputdata.result.userId;
      let singlepatientfulldata = JSON.parse(sessionStorage.getItem("singlepatientfulldata") || '{}');
      this.approveRequest.patientId = singlepatientfulldata.patientId;
      this.approveRequest.mobileNumber = singlepatientfulldata.mobileNumber;
      this.approveRequest.testValue = this.Form.value.testvalue;
      this.apiService.approvemethod(this.approveRequest)
        .subscribe(approveRequestdata => {
          // console.log(approveRequestdata)
          this.route.navigate(['participantstatus']);
        });
    } else {
      alert("Please fill all the mandatory fields")
      // console.log('Not Submit')
    }
  }
}
