import { Component, OnInit } from '@angular/core';
import { CrfSubmittedModel } from '../crfSubmittedmodel';
import { ApiService } from '../api.service';
import { Router } from "@angular/router";
@Component({
  selector: 'app-crf-visit-submited',
  templateUrl: './crf-visit-submited.component.html',
  styleUrls: ['./crf-visit-submited.component.css']
})
export class CrfVisitSubmitedComponent implements OnInit {
  visitName: any;
  crfSubmittedModel = new CrfSubmittedModel();
  CrfSubmitted: any;
  title: any;
  constructor(private apiService: ApiService, private route: Router) {
  }
  ngOnInit() {
    let loginResoutputdata = JSON.parse(sessionStorage.getItem("logindatas") || '{}');
    let Crfpatientsingledata = JSON.parse(sessionStorage.getItem("Crfpatientsingledata") || '{}');
    this.crfSubmittedModel.patientId = Crfpatientsingledata.patientId;
    this.crfSubmittedModel.sectionId = Crfpatientsingledata.sectionId;
    this.crfSubmittedModel.userId = loginResoutputdata.result.userId;
    this.visitName = Crfpatientsingledata.visitName;
    this.apiService.statusSubmitmethod(this.crfSubmittedModel)
      .subscribe(crfSubmitteddata => {
        //console.log(crfSubmitteddata)
        this.CrfSubmitted = crfSubmitteddata.result;
        this.title = crfSubmitteddata.result[0].title;
      });
  }
  getdetailsId(e) {
    // console.log(e.sectionId)
    sessionStorage.setItem('medicalList', JSON.stringify(e));
  }
 
}
