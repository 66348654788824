export class StudyModelResponse {
    "studyId": Number;
    "studyName": String;
    "startDate": any;
    "endDate": any;
    "createdDate": Number;
    "therapeuticIds": Number;
    "therapeuticNames": String;
    "studyShortName": String;
    "studyBannerURL": String;
    "icfUrl": String;
    "pisUrl": String;
    "protocolNumber": String;
    "principalInvestigator": Number;
    "totalVisits": Number;
    "userStatus": String;
    "patientDuration": Number;
    "doseDuration": Number;
    "contactName": String;
    "contactMobile": Number;
    "contactEmail": Number;
    "docAggreementUrl": String;
    "trailSiteName": String;
    "coPrincipalInvestigator": String;
    "trailSites": Number;
    "trailSiteId": Number;
    "studyStatus": String;
}