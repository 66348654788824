import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from "@angular/core";
import { SignaturePad } from 'angular2-signaturepad';
import { Router } from "@angular/router";
import { ApiService } from '../api.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscriber } from "rxjs";
import { NewParticipantModelRequest } from "../newparticipantmodelrequest";
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-newpatient',
  templateUrl: './newpatient.component.html',
  styleUrls: ['./newpatient.component.css']
})
export class NewpatientComponent implements OnInit {
  newParticipantModelReq = new NewParticipantModelRequest();
  dateoffbirth: any;
  Form: FormGroup;
  base64textString: any;
  base64result: any;
  signResult: any;
  selectedgender: any;
  showLabel: any;
  showLabel1: any;
  signimage: any;
  signature: any = null;
  date: any;
  age:any;
  constructor(private datePipe: DatePipe,private apiSevice: ApiService, private route: Router, private formBuilder: FormBuilder,) {
  }
  ngOnInit() {
    this.Form = this.formBuilder.group({
      fullname: [null, Validators.required],
      email: [null, Validators.required],
      mobileNumber: [null, Validators.required],
     // age: [null, Validators.required],
      image: [null],
      date: [null, Validators.required],
      gender:['',Validators.required]
    });
    this.date = new Date();
  }
  
  //observable id proof image
  onChange(evt: any) {
    const file = evt.target.files[0];
    if ((file.type == "application/pdf" )|| (file.type =="image/jpg") ||(file.type =="image/png")) {
      console.log(file.type)
      this.convertToBase64(file);
    }else{
      alert("Please Upload Only (PDF Or JPG ,PNG Images only)")
    }
  }
  convertToBase64(file: File) {
    const observable = new Observable((subscriber: Subscriber<any>) => {
      this.readFile(file, subscriber);
    });
    observable.subscribe((d) => {
      this.base64textString = d;
      this.base64result = this.base64textString.split(',')[1];
      //console.log(this.base64result);
    });
  }
  readFile(file: File, subscriber: Subscriber<any>) {
    const filereader = new FileReader();
    filereader.readAsDataURL(file);
    filereader.onload = () => {
      subscriber.next(filereader.result);
      subscriber.complete();
    };
    filereader.onerror = (error) => {
      subscriber.error(error);
      subscriber.complete();
    }
  }
  //signature image
  handleFileSelect(evt) {
    var files = evt.target.files;
    var file = files[0];
    console.log(file.type)
    if ((file.type == "application/pdf" )|| (file.type =="image/jpg") ||(file.type =="image/png")) {
      console.log(file.type)
    if (files && file) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
    }else{
      alert("Please Upload Only (PDF Or JPG ,PNG Images only)")
    }
  }
  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.signimage = btoa(binaryString);
    //  console.log("hi"+btoa(binaryString));
    // console.log(this.signimage)
  }
  onDateChange($event:any){
    console.log($event.value)
    let patientDateStrF = new Date($event.value)
    console.log(new Date().getFullYear()-patientDateStrF.getFullYear())
    this.age=new Date().getFullYear()-patientDateStrF.getFullYear();
  }
  submitform() {
    console.log(this.base64result)
    console.log(this.signimage)
    if(this.Form.valid ){
      if(this.signimage!= null){
        if( this.base64result != null){
    let studydetailsdata = JSON.parse(sessionStorage.getItem("studydetails") || '{}');
    let loginResoutputdata = JSON.parse(sessionStorage.getItem("logindatas") || '{}');
    let patientdata = JSON.parse(sessionStorage.getItem("data") || '{}');
    let dateoffbirth = this.datePipe.transform(this.Form.value.date, 'yyyy-MM-dd');
console.log(dateoffbirth)
    this.newParticipantModelReq.studyId = studydetailsdata.studyId;
    this.newParticipantModelReq.trailSiteId = loginResoutputdata.result.otherId;
    this.newParticipantModelReq.patientId =
      studydetailsdata.protocolNumber + "-" + loginResoutputdata.result.otherId + "-" + (patientdata.result.length + 1);
    this.newParticipantModelReq.fullName = this.Form.value.fullname;
    this.newParticipantModelReq.gender = this.Form.value.gender;
    this.newParticipantModelReq.mobileNumber = this.Form.value.mobileNumber;
    this.newParticipantModelReq.emailId = this.Form.value.email;
    this.newParticipantModelReq.icfSignType = "E-SIGNATURE";
    this.newParticipantModelReq.age = this.age;
    this.newParticipantModelReq.dob = dateoffbirth;
    this.newParticipantModelReq.enrolledUserId = loginResoutputdata.result.userId;
    this.newParticipantModelReq.icfSignURL = this.signimage;
    this.newParticipantModelReq.proofImageUrl = this.base64result;
    console.log(this.newParticipantModelReq)
    this.apiSevice.enrolledpatientmethod(this.newParticipantModelReq)
      .subscribe(Enrolledpatientdata => {
       console.log(Enrolledpatientdata)
       if (Enrolledpatientdata.status == 'Fail') {
        alert(Enrolledpatientdata.message)
      }
      else {
        this.route.navigate(['participantstatus']);
      }
      });
        }else{
          alert("Please Upload Id Proof (PDf Or JPG ,PNG Images only)")

        }
      }else{
        alert("Please Upload Consent Proof (Pdf Or JPG ,PNG Images only)")
      }
    }else{
    alert("Fill the Form")
    }
  }
  // signwithdate() {
  //   let myElement = document.querySelector('canvas');
  //   let ctx = myElement.getContext('2d');
  //   ctx.font = ' 12px Arial';
  //   ctx.fillText(this.date, 30, 140)
  // }
  // @ViewChild(SignaturePad) signaturePad: SignaturePad;
  // signaturePadOptions: Object = {
  //   'minWidth': 2,
  //   'canvasWidth': 400,
  //   'canvasHeight': 150,
  // };
  // ngAfterViewInit() { }
  // public clear() {
  //   this.signaturePad.clear();
  // }
  // save() {
  //   //console.log(this.signResult);
  //   if (this.signResult) {
  //     this.showLabel1 = false;
  //   }
  // }
  // drawComplete() {
  //   //console.log(this.signaturePad);
  //   this.signature = this.signaturePad.toDataURL('image/png', 1)
  //   this.signature = this.signaturePad.toDataURL('image/png', 0.5);
  //   this.signResult = this.signaturePad.toDataURL().split(',')[1];
  // }
  // drawStart() {
  //   this.signwithdate();
  //   //console.log('begin drawing');
  // }
   //signature type
  // signmethod(value) {
  //   //   console.log(value)
  //   if (value == 'PAPER UPLOAD') {
  //     this.showLabel = true;
  //   } else {
  //     this.showLabel = false;
  //   }
  //   if (value == 'E-SIGNATURE') {
  //     this.showLabel1 = true;
  //   } else {
  //     this.showLabel1 = false;
  //   }
  // }
  
  BmiMethod() {
    alert("This Field is automatically filled")
  }
}

