import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { ApiService } from '../api.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AddLabReportsRequest } from '../addlabreportrequest';
import { Observable, Subscriber } from "rxjs";
@Component({
  selector: 'app-newlabreport',
  templateUrl: './newlabreport.component.html',
  styleUrls: ['./newlabreport.component.css']
})
export class NewlabreportComponent implements OnInit {
  addLabReportsModel = new AddLabReportsRequest();
  Form: FormGroup;
  base64textString: any
  base64result: any;
  constructor( private apiService: ApiService, private route: Router, private formBuilder: FormBuilder,) {
  }
  ngOnInit() {
   
    this.Form = this.formBuilder.group({
      testname: [null, Validators.required],
      testvalue: [null, Validators.required],
    })
  }
  onChange(evt: any) {
    const file = evt.target.files[0];
 //   this.convertToBase64(file);
    if ((file.type == "application/pdf" )|| (file.type =="image/jpg") ||(file.type =="image/png")) {
      console.log(file.type)
      this.convertToBase64(file);
    }else{
      alert("Please Upload Only (PDF Or JPG ,PNG Images only)")
    }
  }
  convertToBase64(file: File) {
    const observable = new Observable((subscriber: Subscriber<any>) => {
      this.readFile(file, subscriber);
    });
    observable.subscribe((d) => {
      this.base64textString = d;
      this.base64result = this.base64textString.split(',')[1];
      //console.log(this.base64result);
    });
  }
  readFile(file: File, subscriber: Subscriber<any>) {
    const filereader = new FileReader();
    filereader.readAsDataURL(file);
    filereader.onload = () => {
      subscriber.next(filereader.result);
      subscriber.complete();
    };
    filereader.onerror = (error) => {
      subscriber.error(error);
      subscriber.complete();
    }
  }
  onSubmit() {
    if(this.Form.valid ){
       if(this.base64result!=null){
    let singlepatientfulldata = JSON.parse(sessionStorage.getItem("singlepatientfulldata") || '{}');
    this.addLabReportsModel.patientId = singlepatientfulldata.patientId;
    let logiResoutputdata = JSON.parse(sessionStorage.getItem("logindatas") || '{}');
    this.addLabReportsModel.userId = logiResoutputdata.result.userId;
    let studydetailsdata = JSON.parse(sessionStorage.getItem("studydetails") || '{}');
    this.addLabReportsModel.studyId = studydetailsdata.studyId;
    this.addLabReportsModel.testName = this.Form.value.testname;
    this.addLabReportsModel.testValue = this.Form.value.testvalue;
    this.addLabReportsModel.fileURL = this.base64result;
    // console.log(this.addLabReportsModel.testName)
    //console.log(this.addLabReportsModel.fileURL)
    // console.log(this.addLabReportsModel.testValue)
    this.apiService.addlabreportsmethodmethod(this.addLabReportsModel)
      .subscribe(labreportsdata => {
        //  console.log(labreportsdata)
        this.route.navigate(['labreport']);
      });
    }else{
      alert("Please Upload Only (PDF Or JPG ,PNG Images only)")

    }
    } else {
      alert("Please fill all the mandatory fields")
      // console.log('Not Submit')
    }
  }
 
}
