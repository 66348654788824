export class NewParticipantModelRequest {
        "studyId": Number;
        "patientId": String;
        "fullName": String;
        "gender": String;
        "mobileNumber": Number;
        "emailId": String;
        "icfSignType": String;
        "trailSiteId": Number;
        "age": Number;
        "dob": any;
        "enrolledUserId": Number;
        "icfSignURL": String;
        "proofImageUrl": String;

}