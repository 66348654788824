import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from "@angular/router";
import { ApiService } from '../api.service';
import { LoginResponseModel } from '../loginresponsemodel';
import { LoginModelRequest } from '../loginmodelrequest';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  radiovalue: Number;
  form: FormGroup;
  loginImage = '../assets/images/landing_left.jpg';
  loginReq = new LoginModelRequest();
  loginRes = new LoginResponseModel();
  password:any
  constructor(private formBuilder: FormBuilder, private router: Router, private apiSevice: ApiService,) {
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      username: [null, Validators.required],
      password: [null, Validators.required],
    });
  }
  //radio button values
  onItemChange(value) {
    this.radiovalue = value;
    //console.log(" Value is : ", value);
  }
  //form submit
  onSubmit() {
    if (this.form.valid && this.radiovalue != null) {
      // console.log(" Value is : ", this.form.value.username);
      this.password = this.form.value.password;
      this.loginReq.username = this.form.value.username;
      this.loginReq.password = this.form.value.password;
      this.loginReq.deviceToken = "";
      this.loginReq.roleId = this.radiovalue;
      this.loginReq.platform = "WEB";
      this.apiSevice.authenticateLogin(this.loginReq)
        .subscribe(logindata => {
          //  console.log(logindata);
          this.loginRes = logindata;
          //status fail
          if (logindata.status == 'Fail') {
            alert(logindata.message)
          }
          else {
            this.router.navigate(['dashboard']);
            sessionStorage.setItem('logindatas', JSON.stringify(logindata));
            sessionStorage.setItem('loginPassword', JSON.stringify(this.password));
            sessionStorage.setItem('loginId', JSON.stringify(this.form.value.username));
          }
        })
    } else {
      alert("fill the form")
      // console.log('Not Submit')
    }
  }
}




