import { Component, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BnNgIdleService } from 'bn-ng-idle'; // import it to your component
import { LoaderService } from './LoaderService';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'DESREM';
  // supportLangaues = ['en', 'fr', 'hi', 'te'];
  // language:any
  showHead: boolean = false;
  logOutTime: any;
  isLoading: Subject<boolean> = this.loader.isLoading;
  ngAfterContentChecked(): void {
    this.changesDetect.detectChanges();
  }

  constructor(private translate: TranslateService, private router: Router, private bnIdle: BnNgIdleService,
    private loader: LoaderService, private http: HttpClient, private changesDetect: ChangeDetectorRef) {
    this.logOutTime = 600;
    //this method is for header and footer not show in login page
    // this.changeLang('en');{
    //   this.translate.use('en');
    // }
    this.translate.use('en');
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        if (event['url'] == '/') {
          this.showHead = false;
        } else if (event['url'] == '/login') {
          this.showHead = false;
        } else {
          this.showHead = true;
        }
        if (event['url'] != '/' && event['url'] != '/login' && event['url'] != '/dashboard') {
          this.logOutTime = this.logOutTime+ 600;
          this.bnIdle.startWatching(this.logOutTime).subscribe((res) => {
            if (res) {
              // console.log("1" + res + this.logOutTime)
              // console.log("session expired");
              this.router.navigate(['login']);
            }
          })
        } else {
          this.bnIdle.startWatching(this.logOutTime).subscribe((res) => {
            if (res) {
              // console.log(res + this.logOutTime)
              // console.log("session expired");
              this.router.navigate(['login']);
            }
          })
        }
      }
    });
    //this method is for automatic logout  
    // this.bnIdle.startWatching(600).subscribe((res) => {
    //   if(res) {
    //     console.log(res)
    //       console.log("session expired");
    //       this.router.navigate(['login']);
    //   } 
    // })

    //this method is for all screen starts scrool zero  for evey component
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });
  }
  onRightClick() {
     alert("Right Click Not Allowed!!")
     return false;
  //return true;
}
}

