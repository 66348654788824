import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../api.service';
import { StudyModelRequest } from '../studymodelrequest';
import { StudyDocumentModel } from '../studydocumentmodel';
import { Router } from "@angular/router";
@Component({
  selector: 'app-study-documents',
  templateUrl: './study-documents.component.html',
  styleUrls: ['./study-documents.component.css']
})
export class StudyDocumentsComponent implements OnInit {
  studyDocumentModels: StudyDocumentModel;
  myimage = '../assets/images/pdf.jpg';
  studyId: any;
  studyModelReq = new StudyModelRequest();
  studyDocumentModelArray: StudyDocumentModel[] = [];
  constructor(private modalService: NgbModal,
    private apiSevice: ApiService, private route: Router) {

  }
  //pdfs open method
  open(content: any, studyDocumentModels: StudyDocumentModel) {
    this.studyDocumentModels = studyDocumentModels;
    this.modalService.open(content, { size: 'lg' });
  }

  ngOnInit() {
    let studydetailsdata = JSON.parse(sessionStorage.getItem("studydetails") || '{}');
    let logiResoutputdata = JSON.parse(sessionStorage.getItem("logindatas") || '{}');
    this.studyModelReq.studyId = studydetailsdata.studyId;
    this.studyModelReq.userId = logiResoutputdata.result.userId
    console.log(this.studyModelReq)
    this.apiSevice.studyDocdumentMethod(this.studyModelReq)
      .subscribe(studydata => {
         console.log(studydata)
        for (let i = 0; i < studydata.result.length; i++) {
          var studyDocumentModel = new StudyDocumentModel();
          studyDocumentModel.studyId = studydata.result[i].studyId;
          //console.log(studyDocumentModel.studyId);
          studyDocumentModel.createdDate = studydata.result[i].createdDate;
          studyDocumentModel.fileUrl = studydata.result[i].fileUrl;
          studyDocumentModel.title = studydata.result[i].title;
          // console.log(studyDocumentModel.title);
          studyDocumentModel.fileType = studydata.result[i].fileType;
          this.studyDocumentModelArray.push(studyDocumentModel);
        }
        // console.log(this.studyDocumentModelArray);
      }, (error) => { console.log(error) }
      );
  }

}