export class UpdateVisitModel {

    "questionId": Number;
    "questionTitle": String;
    "userId": Number;
    "patientId": String;
    "categoryId": Number;
    "sectionId": Number;
    "answer": String;
    "remarks": String;
}