import { Component, OnInit } from '@angular/core';
import { CrfQuestionsRequest } from '../crfquestionsrequest';
import { ApiService } from '../api.service';
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { SubmitVisitModel } from '../submitvisitmodel';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-patentdairynewform',
  templateUrl: './patentdairynewform.component.html',
  styleUrls: ['./patentdairynewform.component.css']
})
export class PatentdairynewformComponent implements OnInit {
  thisIsMyForm: FormGroup;
  section: any;
  CrfQuestionsReq = new CrfQuestionsRequest();
  submitVisitModel = new SubmitVisitModel();
  visitName: any;
  array: any;
  crfResArray: any;
  crfarray: any = [];
  patientdairy: any;
  sectionIds: any;
  maxLength: any;
  constructor(private formBuilder: FormBuilder, private datePipe: DatePipe, private route: Router, private apiService: ApiService,) {
    this.thisIsMyForm = new FormGroup({
      formArrayName: this.formBuilder.array([],Validators.required)
    })
  }
  ngOnInit() {
    let patientdairytittle = JSON.parse(sessionStorage.getItem("patientdairytittle") || '{}');
    this.patientdairy = patientdairytittle;
    let participaintsectionId = JSON.parse(sessionStorage.getItem("participaintsectionId") || '{}');
    let loginResoutputdata = JSON.parse(sessionStorage.getItem("logindatas") || '{}');
    let Crfpatientsingledata = JSON.parse(sessionStorage.getItem("Crfpatientsingledata") || '{}');
    this.CrfQuestionsReq.userId = loginResoutputdata.result.userId;
    this.CrfQuestionsReq.sectionId = participaintsectionId;
    this.visitName = Crfpatientsingledata.visitName;
    this.apiService.crfstatusopenmethod(this.CrfQuestionsReq)
      .subscribe(crfopenstatusdata => {
        // console.log(crfopenstatusdata)
        this.section = crfopenstatusdata.result;
        // console.log(this.section);
        this.buildForm();
      });
  }
   //length validations
   onKeypressEvent(x, event) {
    //console.log(x)
    this.maxLength = x.length;
    if (event.target.value.length == this.maxLength) {
      return false;
    } else {
      return true;
    }
  }
  buildForm() {
    let crfpatientfulldata = JSON.parse(sessionStorage.getItem("Crfpatientsingledata") || '{}');
    let loginResoutputdata = JSON.parse(sessionStorage.getItem("logindatas") || '{}');
    let dayclickeddate = JSON.parse(sessionStorage.getItem("dayclickeddate") || '{}');
    let singlepatientfulldata = JSON.parse(sessionStorage.getItem("singlepatientfulldata") || '{}');
    const controlArray = this.thisIsMyForm.get('formArrayName') as FormArray;
    // console.log("Data" + this.section);
    Object.keys(this.section).forEach((i) => {
      //console.log(this.section[i]);
      controlArray.push(
        this.formBuilder.group({
          answer: [''],
          questionId: this.section[i].questionId,
          sectionId: this.section[i].sectionId,
          createdDate: (this.datePipe.transform(new Date(dayclickeddate), "yyyy-MM-dd")) + '  ' + (this.datePipe.transform(new Date(), "h:mm:ss")),
          patientId: singlepatientfulldata.patientId,
          studyId: crfpatientfulldata.studyId,
          remarks: "",
          userId: loginResoutputdata.result.userId,
        })

      )
    })
    //console.log(controlArray.controls)
  }
  equal() {
    this.crfarray = [];
    if (this.thisIsMyForm.valid) {
      this.array = this.thisIsMyForm.value;
      //console.log(this.array.formArrayName)
      for (let datum of this.array.formArrayName) {
        this.sectionIds = datum.sectionId;
        if (typeof (datum.answer) !== 'undefined') {
          if (datum.answer.toString().includes("GMT")) {
            datum.answer = this.datePipe.transform(datum.answer, 'yyyy-MM-dd')
          }
          datum.answer = datum.answer.toString();
          this.crfarray.push(datum);  // add data into array
        }
      }
      console.log(this.crfarray)
      if (this.sectionIds == 7) {
        this.apiService.participantdairyopenmethod(this.crfarray)
          .subscribe(visitdatas => {
            //console.log(visitdatas.status)
            this.route.navigate(['updateparticipantdiarydetails']);
          })
      } else if (this.sectionIds == 6) {
        this.apiService.participantdairyopenAEmethod(this.crfarray)
          .subscribe(visitdata => {
            // console.log(visitdata)
            this.route.navigate(['updateparticipantdiarydetails']);
          })
      }
    } else {
      alert("Please fill all the mandatory fields")
      // console.log('Not Submit')
    }
  }

}
