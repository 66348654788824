import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from '../api.service';
import { Router } from "@angular/router";
import { PatientModel } from '../patientmodel';
@Component({
  selector: 'app-participant',
  templateUrl: './participant.component.html',
  styleUrls: ['./participant.component.css']
})
export class ParticipantComponent implements OnInit {
  patientModel = new PatientModel();
  participantRess: any = [];
  searchText: any;
  constructor( private apiSevice: ApiService, private route: Router) {
  }

  ngOnInit() {
    let studydetailsdata = JSON.parse(sessionStorage.getItem("studydetails") || '{}');
    let logiResoutputdata = JSON.parse(sessionStorage.getItem("logindatas") || '{}');
    this.patientModel.studyId = studydetailsdata.studyId;
    this.patientModel.userId = logiResoutputdata.result.userId;
    this.patientModel.siteId = logiResoutputdata.result.otherId;
    this.apiSevice.participantmethod(this.patientModel)
      .subscribe(participantdata => {
        //console.log(participantdata);
        this.participantRess = participantdata.result;
        // console.log(this.participantRess[0].patientId)
        sessionStorage.setItem('data', JSON.stringify(participantdata));
        //  console.log( participantdata.result.length+1)
      });
  }
  //Id click after Id display details in Crf view
  getdetailsId(e: any) {
    //console.log(e)
    if (e.status == 'SCREENING-PENDING') {
      this.route.navigate(['upadatereport'])
    } else if (e.status == 'SCREENING-INPROGRESS') {
      this.route.navigate(['inclusion_exclusion']);
    } else if (e.status == 'DROP_OUT') {
      alert("You Can't access this Patient (Dropped).")
    } else if (e.status == 'SCREENING-PASSED' || 'IN-TREATMENT') {
      this.route.navigate(['crf'])
    }
    sessionStorage.setItem('singlepatientfulldata', JSON.stringify(e));
  }

 
}


