import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd,NavigationStart } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  supportLangaues = ['en', 'fr', 'hi', 'te'];
  language:any
  loginName:any;
  constructor(private translate: TranslateService,private router: Router) {
  }
  ngOnInit() {
    let logiResoutputdata = JSON.parse(sessionStorage.getItem("logindatas") || '{}');
    //which user is login
    if (logiResoutputdata.result.roleId == '3') {
      this.loginName = "INVESTIGATOR";
    }
    else if (logiResoutputdata.result.roleId == '4') {
      this.loginName = "CO-INVESTIGATOR";
    }
    else if (logiResoutputdata.result.roleId == '5') {
      this.loginName = "CO-ORDINATOR";
    }
    this.translate.addLangs(this.supportLangaues);
    this.translate.setDefaultLang('en');
    //For app starting only english language see
    let defaultlang='en';
    //  this.changeLang(defaultlang);{
    //   this.translate.use(defaultlang);
    //   console.log(defaultlang)
    //   this.language = defaultlang;
    // }
  }
  //this method is for language selector for evey component
  // changeLang(lng: any): void {
  //   this.translate.use(lng);
  //   console.log(lng)
  //   this.language = lng;
  // }
  
  helpmethod(){
    this.router.navigate(['help']);
   }
  logout() {
    // remove user from local storage to log user out
    sessionStorage.removeItem('logindatas');
    //localStorage.setItem("logindatas",'');
    this.router.navigate(['login']);
  }

 
}
