import { Component, OnInit } from '@angular/core';
import { CrfQuestionsRequest } from '../crfquestionsrequest';
import { ApiService } from '../api.service';
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { UpdateSubjectModel } from '../updateSubjectmodel';
import { Location, DatePipe } from '@angular/common'

@Component({
  selector: 'app-updatesubjectstatus',
  templateUrl: './updatesubjectstatus.component.html',
  styleUrls: ['./updatesubjectstatus.component.css']
})
export class UpdatesubjectstatusComponent implements OnInit {
  crfarray: any = [];
  thisIsMyForm: FormGroup;
  section: any;
  CrfQuestionsReq = new CrfQuestionsRequest();
  updateSubjectModel = new UpdateSubjectModel();
  visitName: any;
  array: any;

  constructor(private datePipe: DatePipe,private formBuilder: FormBuilder, private route: Router, private apiService: ApiService,) {
    this.thisIsMyForm = new FormGroup({
      formArrayName: this.formBuilder.array([],Validators.required)
    })
  }
  ngOnInit() {
    let loginResoutputdata = JSON.parse(sessionStorage.getItem("logindatas") || '{}');
    let Crfpatientsingledata = JSON.parse(sessionStorage.getItem("Crfpatientsingledata") || '{}');
    this.CrfQuestionsReq.userId = loginResoutputdata.result.userId;
    this.CrfQuestionsReq.sectionId = 9;
    this.visitName = Crfpatientsingledata.visitName;
    this.apiService.crfstatusopenmethod(this.CrfQuestionsReq)
      .subscribe(crfopenstatusdata => {
        // console.log(crfopenstatusdata)
        this.section = crfopenstatusdata.result;
        console.log(this.section);
        this.buildForm();
      });
  }
    //today's date show for calender
    todayDate: Date = new Date();
  // Group of form inputs show method
  buildForm() {
    let loginResoutputdata = JSON.parse(sessionStorage.getItem("logindatas") || '{}');
    let singlepatientfulldata = JSON.parse(sessionStorage.getItem("singlepatientfulldata") || '{}');
    let crfpatientfulldata = JSON.parse(sessionStorage.getItem("Crfpatientsingledata") || '{}');
    const controlArray = this.thisIsMyForm.get('formArrayName') as FormArray;
    // console.log("Data"+ this.section);
    Object.keys(this.section).forEach((i) => {
      // console.log(this.section[i]);
      controlArray.push(
        this.formBuilder.group({
          answer: this.section[i].answer,
          questionId: this.section[i].questionId,
          sectionId: 9,
          createdDate: this.section[i].createdDate,
          patientId: singlepatientfulldata.patientId,
          studyId: crfpatientfulldata.studyId,
          remarks: "",
          userId: loginResoutputdata.result.userId,
        })
      )
    })
    //console.log(controlArray.controls)
  }
  // API calling method
  Submit() {
    this.crfarray = [];
    //console.log(this.thisIsMyForm.valid)
    // console.log(this.crfarray)
   
    if (this.thisIsMyForm.valid) {
      this.array = this.thisIsMyForm.value;
      //console.log(this.array.formArrayName)
      for (let datum of this.array.formArrayName) {
        if (typeof (datum.answer) !== 'undefined') {
          if (datum.answer.toString().includes("GMT")) {
            datum.answer = this.datePipe.transform(datum.answer, 'yyyy-MM-dd')
          }
          datum.answer = datum.answer.toString();
          this.crfarray.push(datum);  // add data into array
        }
      }
       console.log(this.crfarray)
      this.apiService.updatesubjectmethod(this.crfarray)
        .subscribe(updatesubjectdata => {
          //console.log(updatesubjectdata)
          this.route.navigate(['crf']);
        })
    } else {
      alert("Please fill all the mandatory fields")
      // console.log('Not Submit')
    }
  }

}
