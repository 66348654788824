import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { StudyDocumentsComponent } from './study-documents/study-documents.component';
import { ParticipantComponent } from './participant/participant.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { UpdatelabreportComponent } from './updatelabreport/updatelabreport.component';
import { InclusionExclusiondataComponent } from './inclusion-exclusiondata/inclusion-exclusiondata.component';
import { ApprovepatientComponent } from './approvepatient/approvepatient.component';
import { NewpatientComponent } from './newpatient/newpatient.component';
import { CrfComponent } from './crf/crf.component';
import { LabreportComponent } from './labreport/labreport.component';
import { NewlabreportComponent } from './newlabreport/newlabreport.component';
import { CrfvisitquestionsComponent } from './crfvisitquestions/crfvisitquestions.component';
import { CrfvisitDemographicComponent } from './crfvisit-demographic/crfvisit-demographic.component';
import { CrfVisitSubmitedComponent } from './crf-visit-submited/crf-visit-submited.component';
import { PatientDairyComponent } from './participantdairy/patient-dairy.component';
import { AddnewmedicalformComponent } from './addnewmedicalform/addnewmedicalform.component';
import { QuerieslistComponent } from './querieslist/querieslist.component';
import { QuerydetailsComponent } from './querydetails/querydetails.component';
import { UpdatequeryComponent } from './updatequery/updatequery.component';
import { UpdatesubjectstatusComponent } from './updatesubjectstatus/updatesubjectstatus.component';
import { CrfformdataComponent } from './crfformdata/crfformdata.component';
import { PatientdairydetailsComponent } from './patientdairydetails/patientdairydetails.component';
import { PatientdairydetailsopenformComponent } from './submitpatientdairydetail/patientdairydetailsopenform.component';
import { PatientdairydetailssubmitformComponent } from './participantdairylist/patientdairydetailssubmitform.component';
import { PatentdairyupdateformComponent } from './patentdairyupdate/patentdairyupdateform.component';
import { PatentdairynewformComponent } from './patentdairysubmit/patentdairynewform.component';
import { AuthenticationGuard } from './authentication.guard';
import { HelpComponent } from './help/help.component';

const routes: Routes = [  

  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: "login", component: LoginComponent },
  { path: "dashboard", component: DashboardComponent ,canActivate:[AuthenticationGuard]},
  { path: "studydocuments", component: StudyDocumentsComponent,canActivate:[AuthenticationGuard] },
  { path: "participantstatus", component: ParticipantComponent ,canActivate:[AuthenticationGuard]},
  { path: "myprofile", component: MyProfileComponent,canActivate:[AuthenticationGuard] },
  { path: "upadatereport", component: UpdatelabreportComponent ,canActivate:[AuthenticationGuard]},
  { path: "inclusion_exclusion", component: InclusionExclusiondataComponent,canActivate:[AuthenticationGuard] },
  { path: "approvepatient", component: ApprovepatientComponent,canActivate:[AuthenticationGuard] },
  { path: "newparticipant", component: NewpatientComponent,canActivate:[AuthenticationGuard] },
  { path: "crf", component: CrfComponent ,canActivate:[AuthenticationGuard]},
  { path: "labreport", component: LabreportComponent ,canActivate:[AuthenticationGuard]},
  { path: "newlabreport", component: NewlabreportComponent,canActivate:[AuthenticationGuard] },
  { path: "crfvisitquestions", component: CrfvisitquestionsComponent ,canActivate:[AuthenticationGuard]},
  { path: "crfvisitDemographic", component: CrfvisitDemographicComponent,canActivate:[AuthenticationGuard] },
  { path: "CrfVisitSubmited", component: CrfVisitSubmitedComponent,canActivate:[AuthenticationGuard] },
  { path: "participantdiary", component: PatientDairyComponent ,canActivate:[AuthenticationGuard]},
  { path: "submitmedical", component: AddnewmedicalformComponent ,canActivate:[AuthenticationGuard]},
  { path: "querieslist", component: QuerieslistComponent,canActivate:[AuthenticationGuard] },
  { path: "querydetails", component: QuerydetailsComponent ,canActivate:[AuthenticationGuard]},
  { path: "updatequery", component: UpdatequeryComponent ,canActivate:[AuthenticationGuard]},
  { path: "updatesubjectstatus", component: UpdatesubjectstatusComponent,canActivate:[AuthenticationGuard] },
  { path: "updatemedical", component: CrfformdataComponent,canActivate:[AuthenticationGuard] },
  { path: "updatedata", component: PatientdairydetailsComponent,canActivate:[AuthenticationGuard] },
  { path: "submitparticipantdiarydetails", component: PatientdairydetailsopenformComponent,canActivate:[AuthenticationGuard] },
  { path: "updateparticipantdiarydetails", component: PatientdairydetailssubmitformComponent,canActivate:[AuthenticationGuard] },
  { path: "updateparticiapantdiary", component: PatentdairyupdateformComponent,canActivate:[AuthenticationGuard] },
  { path: "newparticipantdiary", component: PatentdairynewformComponent,canActivate:[AuthenticationGuard] },
  {path:"help",component:HelpComponent,canActivate:[AuthenticationGuard]},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
  
 }
