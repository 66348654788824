import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { ApiService } from '../api.service';
import { LabReportModel } from '../LabReportModel';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LabReportRequest } from '../LabReportRequest';
@Component({
  selector: 'app-labreport',
  templateUrl: './labreport.component.html',
  styleUrls: ['./labreport.component.css']
})
export class LabreportComponent implements OnInit {
  labReportsModels: LabReportModel;
  labReportReq = new LabReportRequest();
  labReportsModel: LabReportModel[];
  showlabels: any;
  pdfShow: any;
  constructor( private modalService: NgbModal, private route: Router, private apiService: ApiService) {
  }
  open(content: any, labReportsModels: LabReportModel) {
    this.labReportsModels = labReportsModels;
    console.log(labReportsModels)
    // if (labReportsModels.fileType == "Image") {
    //   this.showlabels = true;
    // } else {
    //   this.showlabels = false;
    // }
    // if (labReportsModels.fileType == "PDF") {
    //   this.pdfShow = true;
    // } else {
    //   this.pdfShow = false;
    // }
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
  }
  ngOnInit() {
    let singlepatientfulldata = JSON.parse(sessionStorage.getItem("singlepatientfulldata") || '{}');
    this.labReportReq.patientId = singlepatientfulldata.patientId;
    let logiResoutputdata = JSON.parse(sessionStorage.getItem("logindatas") || '{}');
    this.labReportReq.userId = logiResoutputdata.result.userId;
    this.apiService.labreportmethod(this.labReportReq)
      .subscribe(labreportdata => {
        //console.log(labreportdata)
        this.labReportsModel = labreportdata.result;
        //console.log(this.labReportsModel)

      })
  }
}
