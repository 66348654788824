import { Component, OnInit } from '@angular/core';
import { PatientDairyModel } from '../patientdairymodel';
import { Router } from "@angular/router";
import { DatePipe } from '@angular/common';
import { ApiService } from '../api.service';
@Component({
  selector: 'app-patientdairydetails',
  templateUrl: './patientdairydetails.component.html',
  styleUrls: ['./patientdairydetails.component.css']
})
export class PatientdairydetailsComponent implements OnInit {
  dayclickeddate: any;
  patientDairyModel = new PatientDairyModel();
  fillstatusST: any;
  fillstatusAE: any;
  fillstatusCM: any;
  treatmentSectionId: Number;
  aeSectionId: Number;
  cmSectionId: Number;
  participantDaiary: any;
  constructor( private route: Router, private apiService: ApiService, private datePipe: DatePipe) {
  }
  ngOnInit() {
    this.fillstatusST = "OPEN"
    this.fillstatusAE = "OPEN"
    this.fillstatusCM = "OPEN"
    this.treatmentSectionId = 5;
    this.aeSectionId = 6;
    this.cmSectionId = 7;
    let dayclickeddate = JSON.parse(sessionStorage.getItem("dayclickeddate") || '{}');
    this.dayclickeddate = this.datePipe.transform(new Date(dayclickeddate), "yyyy-MM-dd");
    //console.log( this.dayclickeddate);
    let loginResoutputdata = JSON.parse(sessionStorage.getItem("logindatas") || '{}');
    let Crfpatientsingledata = JSON.parse(sessionStorage.getItem("Crfpatientsingledata") || '{}');
    let singlepatientfulldata = JSON.parse(sessionStorage.getItem("singlepatientfulldata") || '{}');
    this.patientDairyModel.patientId = singlepatientfulldata.patientId;
    this.patientDairyModel.studyId = Crfpatientsingledata.studyId;
    this.patientDairyModel.userId = loginResoutputdata.result.userId;
    this.patientDairyModel.fromDate = this.dayclickeddate;
    this.patientDairyModel.toDate = this.dayclickeddate;
    this.apiService.patientdairymethod(this.patientDairyModel)
      .subscribe(participantDaiarydata => {
        // console.log(participantDaiarydata);
        if (participantDaiarydata.result.length > 0) {
          for (let index = 0; index < participantDaiarydata.result.length; index++) {
            this.participantDaiary = participantDaiarydata.result[index].sectionId;
            // console.log(this.participantDaiary)
            if (participantDaiarydata.result[index].sectionId == 5) {
              this.fillstatusST = "SUBMITTED"
            }
            else if (participantDaiarydata.result[index].sectionId == 6) {
              this.fillstatusAE = "SUBMITTED"
            }
            else if (participantDaiarydata.result[index].sectionId == 7) {
              this.fillstatusCM = "SUBMITTED"
            }
          }
        }
      })
  }
  onChange(e: any) {
    //console.log(e);
    sessionStorage.setItem('participaintsectionId', JSON.stringify(e));
    if (e == 5) {
      if (this.fillstatusST == "SUBMITTED") {
        this.route.navigate(['updateparticipantdiarydetails']);
      }
      else {
        this.route.navigate(['submitparticipantdiarydetails']);
      }
    }
    else if (e == 6) {
      if (this.fillstatusAE == "SUBMITTED") {
        this.route.navigate(['updateparticipantdiarydetails']);
      }
      else {
        this.route.navigate(['submitparticipantdiarydetails']);
      }
    }
    else {
      if (this.fillstatusCM == "SUBMITTED") {
        this.route.navigate(['updateparticipantdiarydetails']);
      }
      else {
        this.route.navigate(['submitparticipantdiarydetails']);
      }
    }
  }
  
}
