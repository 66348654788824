import { Component, OnInit } from '@angular/core';
import { CrfQuestionsRequest } from '../crfquestionsrequest';
import { ApiService } from '../api.service';
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { SubmitVisitModel } from '../submitvisitmodel';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-patientdairydetailsopenform',
  templateUrl: './patientdairydetailsopenform.component.html',
  styleUrls: ['./patientdairydetailsopenform.component.css']
})
export class PatientdairydetailsopenformComponent implements OnInit {
  thisIsMyForm: FormGroup;
  section: any;//form build ki key 
  CrfQuestionsReq = new CrfQuestionsRequest();
  submitVisitModel = new SubmitVisitModel();
  visitName: any;
  array: any;
  crfResArray: any;
  crfarray: any = [];
  sectionIds: any;
  visitid: any;
  maxLength: any;
  CurrentDT: any;
  constructor(private formBuilder: FormBuilder, private datePipe: DatePipe, private route: Router, private apiService: ApiService,) {
    this.thisIsMyForm = new FormGroup({
      formArrayName: this.formBuilder.array([],Validators.required)
    })
  }
  ngOnInit() {
    let participaintsectionId = JSON.parse(sessionStorage.getItem("participaintsectionId") || '{}');
    let loginResoutputdata = JSON.parse(sessionStorage.getItem("logindatas") || '{}');
    this.CrfQuestionsReq.userId = loginResoutputdata.result.userId;
    this.CrfQuestionsReq.sectionId = participaintsectionId;
    if (participaintsectionId == 5) {
      this.visitid = "Study Treatment";
    } else if (participaintsectionId == 6) {
      this.visitid = "Adverse Events";
    } else if (participaintsectionId == 7) {
      this.visitid = "Concomitant Medication";
    }
    this.apiService.crfstatusopenmethod(this.CrfQuestionsReq)
      .subscribe(crfopenstatusdata => {
        //console.log(crfopenstatusdata)
        this.section = crfopenstatusdata.result;
        console.log(this.section);
        this.buildForm();
      });
  }
  buildForm() {
    let crfpatientfulldata = JSON.parse(sessionStorage.getItem("Crfpatientsingledata") || '{}');
    let loginResoutputdata = JSON.parse(sessionStorage.getItem("logindatas") || '{}');
    let dayclickeddate = JSON.parse(sessionStorage.getItem("dayclickeddate") || '{}');
    let singlepatientfulldata = JSON.parse(sessionStorage.getItem("singlepatientfulldata") || '{}');
    const controlArray = this.thisIsMyForm.get('formArrayName') as FormArray;
    console.log("Data" + dayclickeddate);
    Object.keys(this.section).forEach((i) => {
      //  console.log(this.section[i]);
      controlArray.push(
        this.formBuilder.group({
          answer: [''],
          questionId: this.section[i].questionId,
          sectionId: this.section[i].sectionId,
          //Date format yyyy-MM-dd(selected) HH:mm:ss(current time)
          createdDate: (this.datePipe.transform(new Date(dayclickeddate), "yyyy-MM-dd")) + '  ' + (this.datePipe.transform(new Date(), "h:mm:ss")),
          patientId: singlepatientfulldata.patientId,
          studyId: crfpatientfulldata.studyId,
          remarks: "",
          userId: loginResoutputdata.result.userId,
        })
      )
    })
    //console.log(controlArray.controls);
  }
  equal() {
    if (this.thisIsMyForm.valid) {
      this.array = this.thisIsMyForm.value;
      //console.log(this.array.formArrayName)
      for (let datum of this.array.formArrayName) {
        this.sectionIds = datum.sectionId;
        if (typeof (datum.answer) !== 'undefined') {
          if (datum.answer.toString().includes("GMT")) {
            datum.answer = this.datePipe.transform(datum.answer, 'yyyy-MM-dd')
          }
          datum.answer = datum.answer.toString();
          this.crfarray.push(datum);  // add data into array
        }
      }
      console.log(this.crfarray)
      if (this.sectionIds == 5 || this.sectionIds == 7) {
        // console.log(this.sectionIds)
        this.apiService.participantdairyopenmethod(this.crfarray)
          .subscribe(visitdatas => {
            console.log("1" + visitdatas)
            this.route.navigate(['updatedata']);
          })
      } else if (this.sectionIds == 6) {
        this.apiService.participantdairyopenAEmethod(this.crfarray)
          .subscribe(visitdata => {
            console.log("2" + visitdata)
            this.route.navigate(['updatedata']);
          })
      }
    } else {
      alert("Please fill all the mandatory fields")
      // console.log('Not Submit')
    }
  }
  //length validations
  onKeypressEvent(x, event) {
    this.maxLength = x.length;
    //console.log(event.target.value.length);
    if (event.target.value.length == this.maxLength) {
      return false;
    }
  }

}
