import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from "@angular/router";
@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit {
  showpdfIn: any;
  showpdfCo: any;
  myimage = '../assets/images/pdf.jpg';
  constructor(
    private route: Router, private modalService: NgbModal,) {
  }
  open(content: any) {
    this.modalService.open(content, {size:"lg"});
  }
  opens(contents: any) {
    this.modalService.open(contents, {size:"lg"});
  }
  //pdfs
  // user(users: any) {
  //   this.modalService.open(users);
  // }
  // openIn(usersIn: any) {
  //   this.modalService.open(usersIn,{size:"lg"});
  // }
  // openCo(usersCo: any) {
  //   this.modalService.open(usersCo);
  // }
  // openPr(usersPr: any) {
  //   this.modalService.open(usersPr,{size:"lg"});
  // }
  ngOnInit() {
    let logiResoutputdata = JSON.parse(sessionStorage.getItem("logindatas") || '{}');
   // console.log(logiResoutputdata)
    //which user is login
    // if (logiResoutputdata.result.roleId == '3') {
    //   this.showpdfIn = true;
    //   //console.log(this.showpdfIn)
    // } else {
    //   this.showpdfIn = false;
    // }
    // if (logiResoutputdata.result.roleId == '4') {
    //   this.showpdfCo = true;
    //   //console.log(this.showpdfCo)
    // } else if (logiResoutputdata.result.roleId == '5') {
    //   this.showpdfCo = true;
    //   //console.log(this.showpdfCo)
    // } else {
    //   this.showpdfCo = false;
    // }
  }
  logout() {
    // remove user from local storage to log user out
    sessionStorage.removeItem('datas');
    this.route.navigate(['login']);
  }
}
