import { Component, OnInit,AfterContentChecked, ChangeDetectorRef  } from '@angular/core';
import { ApiService } from '../api.service';
import { Router } from "@angular/router";
import { MyprofileRequestModel } from '../myprofilerequestmodel';
import { MyprofileResponseModel } from '../myprofileresponsemodel';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { changePasswordModel } from '../changePasswordModel';
@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.css']
})
export class MyProfileComponent implements OnInit {
  logindisplayName: String;
  loginPicture: String;
  myprofileRes = new MyprofileResponseModel();
  myprofileReq = new MyprofileRequestModel();
  loginName: any;
  form: FormGroup;
  changePassword = new changePasswordModel();
  old: any;
  oldPassword: any;
  status: any;
  constructor(private changesDetect: ChangeDetectorRef,private formBuilder: FormBuilder,private apiService: ApiService,private modalService: NgbModal, private route: Router) {
    
  }
  ngAfterContentChecked(): void {
    this.changesDetect.detectChanges();
  }
  open(content: any) {
    this.modalService.open(content);
  }
  checkPassword(event: any) {
    let loginPassword = JSON.parse(sessionStorage.getItem("loginPassword") || '{}');
    // console.log(loginPassword)
  }
  ngOnInit() {
    this.form = this.formBuilder.group({
      oldPassword: [null, Validators.required],
      newPassword: [null, Validators.required],
      confirmNewPassword: [null, Validators.required],
    });
    let logiResoutputdata = JSON.parse(sessionStorage.getItem("logindatas") || '{}');
    if (logiResoutputdata.result.roleId == '3') {
      this.loginName = "Investigator";
    }
    else if (logiResoutputdata.result.roleId == '4') {
      this.loginName = "Co-Investigator";
    }
    else if (logiResoutputdata.result.roleId == '5') {
      this.loginName = "Coordinator";
    }
    //to dispaly login details names
    this.logindisplayName = logiResoutputdata.result.displayName;
    this.loginPicture = logiResoutputdata.result.displayPictureUrl;
    //myprofiledata api
    this.myprofileReq.userId = logiResoutputdata.result.userId;
    this.myprofileReq.siteId = logiResoutputdata.result.otherId;
    this.apiService.myprofilemethod(this.myprofileReq)
      .subscribe(myprofiledata => {
        //console.log(myprofiledata);
        this.myprofileRes = myprofiledata.result;
      })
  }
  validateoldPassword() {
    let loginPassword = JSON.parse(sessionStorage.getItem("loginPassword") || '{}');
    console.log(loginPassword)
    if (loginPassword == this.form.value.oldPassword) {
      this.oldPassword = false;
    } else {
      this.oldPassword = true;
    }
  }
  validateConfirmPassword(): void {
    if (this.form.value.newPassword == this.form.value.confirmNewPassword) {
      this.old = false;
    } else {
      this.old = true;
    }
  }
  onSubmit() {
    if(!this.old){
      if (this.form.valid) {
        let logiResoutputdata = JSON.parse(sessionStorage.getItem("logindatas") || '{}');
        this.changePassword.oldPassword = this.form.value.oldPassword;
        this.changePassword.newPassword = this.form.value.newPassword;
        this.changePassword.userId = logiResoutputdata.result.userId;
        this.apiService.changePasswordmethod(this.changePassword)
          .subscribe(passworddata => {
            console.log(passworddata)
            if (passworddata.status == 'Fail') {
              alert(passworddata.message);
            }
            else if (passworddata.status == 'Success') {
              this.status = passworddata.status;
              //this.others();
              this.route.navigate(['login']);
            }
  
          })
      } else {
        alert("Please fill all the Mandatory Fields and Password Length should be minimum 4")
      }
    }
    else{
      alert("New Password and Confirm New Password is not matched!")
    }
  }
}
